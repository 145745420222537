import React, { useState } from "react";
import styles from "./resetPasswordRequest.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import * as v from "valibot";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useResetPasswordMutation } from "../../features/api/user/userApi";
import { Input } from "../common/input/Input";

const schema = v.object({
  email: v.pipe(
    v.string(),
    v.nonEmpty("Veuillez entrer votre adresse email"),
    v.email("L'email est mal formaté"),
    v.maxLength(50, "Votre email est trop long")
  ),
});
export const ResetPasswordRequest = () => {
  const [serverErr, setServerErr] = useState(false);
  const [userNotExist, setUserNotExist] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: valibotResolver(schema),
  });
  const navigate = useNavigate();

  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();

  const onSubmit = async (userInfo) => {
    try {
      await resetPassword(userInfo).unwrap();
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (err) {
      if (err?.data?.errorCode === 400) {
        userNotExistsErr();
      } else {
        setServerErr(true);
      }
    }
  };

  const userNotExistsErr = () => {
    setUserNotExist(true);
    setTimeout(() => {
      setUserNotExist(false);
    }, 3000);
  };
  return (
    <div className={styles.reset_password_container}>
      <section className={styles.triangle_container}>
        <div></div>
        <div></div>
        <div></div>
        <p>J</p>
      </section>
      <form
        className={styles.reset_password_form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <section>
          <Input
            label="E-mail"
            name="email"
            register={register}
            error={errors.email}
            color="#ffffff"
          />
        </section>
        <section className={styles.submit_btn_info}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "En cour..." : "Réinitialiser"}
          </button>
          {userNotExist && (
            <p className={`${styles.reset_info} ${styles.err_msg}`}>
              L'utilisateur n'existe pas avec cette adresse e-mail
            </p>
          )}

          {serverErr && (
            <p className={`${styles.reset_info} ${styles.err_msg}`}>
              Un problème s'est produit, veuillez réessayer plus tard
            </p>
          )}
          {isSuccess && (
            <p className={`${styles.reset_info} ${styles.success_msg}`}>
              Veuillez vérifier votre adresse e-mail pour compléter la
              réinitialisation du mot de passe
            </p>
          )}
        </section>
        <ul className={styles.redirect_btn_group}>
          <li>
            <NavLink to="/login">Se connecter</NavLink>
          </li>
          <li>
            <NavLink to="/sign-up">S'inscrire</NavLink>
          </li>
        </ul>
      </form>
    </div>
  );
};
