import React from "react";

export const CGL = () => {
  return (
    <div>
      <h3>CONDITION DE LIVRAISON</h3>
      <h4>1. Règles Générales</h4>
      <p>
        Les produits commandés par l’Acheteur, conformément aux présentes
        Conditions Générales de Vente (CGV), seront livrés à l’adresse indiquée
        lors de la Commande (« Adresse de livraison »). <br />
        JFrag propose également, pour certains produits, la livraison dans des
        points de retrait en France métropolitaine.
      </p>
      <h4>2. Modes de livraison du site</h4>
      <p>
        Les modes de livraison disponibles varient selon les produits. Certains
        modes, comme le retrait en magasin ou la livraison par coursier, peuvent
        ne pas être proposés pour toutes les commandes.
      </p>
      <h4>2.1 Livraison pour la France métropolitaine, la Corse et Monaco</h4>
      <li>
        <b>Livraison à domicile (standard) : </b> Le colis est déposé dans la
        boîte aux lettres à l'adresse fournie. Si la boîte aux lettres est trop
        petite, un avis de passage sera laissé pour retrait à l’endroit indiqué.
        Si le colis n’est pas retiré à temps, il sera retourné à JFrag et
        l'Acheteur sera remboursé selon le moyen de paiement initial.
      </li>
      <li>
        <b> Livraison rapide : </b> L’Acheteur reçoit une notification par email
        la veille de la livraison, avec possibilité de modifier l'adresse ou de
        reprogrammer la livraison. Le jour de la livraison, un créneau horaire
        est communiqué avec des options de reprogrammation. Si le colis est
        déposé dans un point de proximité, il doit être retiré dans les délais
        spécifiés, sinon il sera retourné à JFrag et remboursé.
      </li>
      <li>
        <b> Livraison sur rendez-vous (hors Corse) : </b> Ce service est
        disponible uniquement sur www.jfrag.fr (hors application mobile) et
        selon l’adresse de livraison. La veille, une notification est envoyée,
        avec possibilité de reprogrammation. Si le colis est retiré dans un
        point relais, il doit l’être dans le délai indiqué.
      </li>
      <li>
        <b> Livraison par coursier (hors Corse) : </b> Ce service est disponible
        dans certaines villes via www.jfrag.fr. Une signature électronique sera
        demandée au moment de la livraison.
      </li>
      <h4>2.2 Livraison au Luxembourg</h4>
      <li>
        <b> Livraison à domicile par Chronopost : </b> La livraison se fait avec
        signature électronique. L’Acheteur peut reprogrammer la livraison ou
        changer l’adresse si nécessaire. Si le colis n’est pas retiré à temps,
        il sera retourné à JFrag et remboursé.
      </li>
      <h4>2.3 Livraison dans d'autres pays</h4>
      <p>
        JFrag utilise les services postaux locaux pour les livraisons
        internationales. Les modalités peuvent varier selon les pays.
      </p>
      <h4>3. Anomalie, avarie, colis endommagé</h4>
      <p>
        L’Acheteur ou le destinataire doit vérifier l’état du colis à la
        livraison. En cas de problème (colis endommagé, produits manquants), les
        procédures suivantes doivent être respectées :
      </p>
      <li>
        <b> Livraison à domicile :</b> Si le livreur est présent, refuser la
        livraison et émettre des réserves. Si le livreur est absent, rapporter
        le colis non ouvert à la Poste et signaler l’incident au service client.
      </li>
      <li>
        <b> Livraison en relais ou en magasin : </b> Ne pas accepter le colis et
        contacter immédiatement le service client pour signaler l’anomalie.
      </li>
      <li>
        <b> Livraison par coursier : </b> Refuser la livraison et émettre des
        réserves auprès du livreur, puis contacter le service client.
      </li>
      <h4>4. Délais de livraison</h4>
      <p>Les délais de livraison varient selon le mode choisi :</p>
      <li>
        <b>France métropolitaine et Monaco : </b>Livraison standard en 3 à 4
        jours ouvrables, livraison rapide en 1 à 2 jours.
      </li>
      <li>
        <b>Luxembourg :</b>Livraison en 3 à 4 jours ouvrables.
      </li>
      <li>
        <b>Belgique :</b>Livraison à domicile en 2 à 3 jours ouvrables. <br />
        En cas de dépassement des délais, l’Acheteur peut annuler la commande
        après mise en demeure auprès du service client.
      </li>
    </div>
  );
};
