import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { Header } from "./components/common/header/Header";
import { Footer } from "./components/common/footer/Footer";
import { AllProduct } from "./pages/AllProduct/AllProduct";
import { DetailProduct } from "./pages/detailProduct/DetailProduct";
import { Basket } from "./pages/Basket/Basket";
import { OrderSuccess } from "./pages/OrderSuccess/OrderSuccess";
import { Login } from "./pages/login/Login";
import { PayOrder } from "./pages/payOrder/PayOrder";
import { Registration } from "./pages/registration/Registration";
import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import { ProtectedRouteWithoutLogin } from "./components/protectedRoute/ProtectedRouteWithoutLogin";
import { Account } from "./pages/account/Account";
import { ScrollToTop } from "./components/common/scrollToTop/ScrollToTop";
import { OrderDetails } from "./pages/orderDetails/OrderDetails";
import { Gents } from "./pages/gents/Gents";
import { Ladies } from "./pages/ladies/Ladies";
import { About } from "./pages/about/About";
import CookieConsent from "react-cookie-consent";
import { useMediaQuery } from "./customHooks/mediaScreen";

import { PM } from "./components/allregulations/payment/paymentmoyen/PM";
import { PP } from "./components/allregulations/payment/paymentpolitique/PP";
import { CGL } from "./components/allregulations/livrasion/cgl/CGL";
import { CGR } from "./components/allregulations/livrasion/cgr/CGR";
import { CG } from "./components/allregulations/contact/cg/CG";
import { SM } from "./components/allregulations/contact/sm/SM";
import { CGU } from "./components/allregulations/conditiongeneral/cgu/CGU";
import { CGV } from "./components/allregulations/conditiongeneral/cgv/CGV";
import { Mentionlegal } from "./components/allregulations/conditiongeneral/mentionlegal/Mentionlegal";
import { TermsAndCondition } from "./pages/termsAndCondition/TermsAndCondition";
import { UpdatePassword } from "./pages/updatePassword/UpdatePassword";
import { ResetPassword } from "./pages/resetPassword/ResetPassword";
import { AccountVerification } from "./pages/accountVerification/AccountVerification";

function App() {
  const isMediumScreen = useMediaQuery("(max-width: 768px)");
  const isSmallScreen = useMediaQuery("(max-width: 460px)");

  const bannerStyle = {
    background: "#000",
    color: "white",
    textAlign: "justify",
    fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "16px",
  };

  const buttonStyle = {
    fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "16px",
  };
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        {/* ----------------- */}
        <Route path="/pm" element={<TermsAndCondition component={<PM />} />} />
        <Route path="/pp" element={<TermsAndCondition component={<PP />} />} />
        <Route
          path="/cgl"
          element={<TermsAndCondition component={<CGL />} />}
        />
        <Route
          path="/cgr"
          element={<TermsAndCondition component={<CGR />} />}
        />
        <Route path="/cg" element={<TermsAndCondition component={<CG />} />} />
        <Route path="/sm" element={<TermsAndCondition component={<SM />} />} />
        <Route
          path="/cgu"
          element={<TermsAndCondition component={<CGU />} />}
        />
        <Route
          path="/cgv"
          element={<TermsAndCondition component={<CGV />} />}
        />
        <Route
          path="/mentionlegal"
          element={<TermsAndCondition component={<Mentionlegal />} />}
        />
        {/* ----------------- */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gents" element={<Gents />} />
        <Route path="/ladies" element={<Ladies />} />
        <Route path="/basket" element={<Basket />} />
        <Route path="/all-product" element={<AllProduct />} />
        <Route path="/account-verification" element={<AccountVerification />} />
        <Route path="/product/:productId" element={<DetailProduct />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/place-order"
          element={
            <ProtectedRoute>
              <PayOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-success"
          element={
            <ProtectedRoute>
              <OrderSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase-details/:orderId"
          element={
            <ProtectedRoute>
              <OrderDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <ProtectedRouteWithoutLogin>
              <Login />
            </ProtectedRouteWithoutLogin>
          }
        />
        <Route
          path="/sign-up"
          element={
            <ProtectedRouteWithoutLogin>
              <Registration />
            </ProtectedRouteWithoutLogin>
          }
        />
      </Routes>
      <CookieConsent
        expires={180}
        style={bannerStyle}
        acceptOnScroll={true}
        buttonStyle={buttonStyle}
        buttonText={"J'ai compris"}
        acceptOnScrollPercentage={50}
      >
        Ce site utilise uniquement des cookies essentiels pour assurer le bon
        fonctionnement du site, tels que l'authentification des utilisateurs et
        la gestion des sessions. Aucune donnée personnelle n'est collectée. En
        poursuivant votre navigation sur ce site, vous acceptez l'utilisation de
        ces cookies.
      </CookieConsent>
      <Footer />
    </Router>
  );
}

export default App;
