import React, { useState } from "react";
import styles from "./signup.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useSignUpMutation } from "../../features/api/user/userApi";
import * as v from "valibot";
import { Input } from "../common/input/Input";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
const schema = v.object({
  firstName: v.pipe(
    v.string("Prénom est obligatoire"),
    v.minLength(3, "Doit comporter au moins 3 caractères")
  ),
  lastName: v.pipe(
    v.string("Nom est obligatoire"),
    v.minLength(3, "Doit comporter au moins 3 caractères")
  ),
  phone: v.pipe(
    v.string("Numéro de portable est obligatoire"),
    v.length(10, "Doit comporter 10 caractères"),
    v.check((value) => /^\d+$/.test(value), "Chiffres uniquement")
  ),
  email: v.pipe(
    v.string(),
    v.nonEmpty("Veuillez entrer votre adresse email"),
    v.email("L'email est mal formaté"),
    v.maxLength(50, "Votre email est trop long")
  ),
  password: v.pipe(
    v.string(),
    v.regex(/[A-Z]/, "Doit comporter au moins une lettre majuscule"), // At least one uppercase letter
    v.regex(/[a-z]/, "Doit comporter au moins une lettre minuscule"), // At least one lowercase letter
    v.regex(/[0-9]/, "Doit comporter au moins un chiffre"), // At least one number
    v.regex(
      /[!@#$%^&*(),.?":{}|<>+-]/,
      "Doit comporter au moins un caractère spécial"
    ), // At least one special character
    v.minLength(8, "Doit comporter au moins 8 caractères")
  ),
});

export const SignUp = () => {
  const [serverErr, setServerErr] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
    },
    resolver: valibotResolver(schema),
  });
  const navigate = useNavigate();

  const [signUp, { isLoading, isSuccess }] = useSignUpMutation();

  const onSubmit = async (userInfo) => {
    try {
      await signUp(userInfo).unwrap();
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (err) {
      if (err.data.errorCode === 400) {
        emailExistsErr();
      } else {
        setServerErr(true);
      }
    }
  };

  const emailExistsErr = () => {
    setEmailExists(true);
    setTimeout(() => {
      setEmailExists(false);
    }, 3000);
  };

  return (
    <div className={styles.sign_up_container}>
      <section className={styles.triangle_container}>
        <div></div>
        <div></div>
        <div></div>
        <p>J</p>
      </section>
      <form className={styles.sign_up_form} onSubmit={handleSubmit(onSubmit)}>
        <section>
          <Input
            label="Prénom"
            name="firstName"
            register={register}
            error={errors.firstName}
            color="#ffffff"
          />
          <Input
            label="Nom"
            name="lastName"
            register={register}
            error={errors.lastName}
            color="#ffffff"
          />
          <Input
            label="Portable"
            name="phone"
            register={register}
            error={errors.phone}
            color="#ffffff"
          />
          <Input
            label="E-mail"
            name="email"
            register={register}
            error={errors.email}
            color="#ffffff"
          />
          <Input
            label="Mot de passe"
            name="password"
            register={register}
            error={errors.password}
            color="#ffffff"
          />
        </section>
        <section className={styles.sign_up_btn_info}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "En cour..." : "S'incrire"}
          </button>
          {emailExists && (
            <p className={`${styles.signup_info} ${styles.err_msg}`}>
              Un utilisateur existe déjà avec cette adresse e-mail
            </p>
          )}

          {serverErr && (
            <p className={`${styles.signup_info} ${styles.err_msg}`}>
              Un problème s'est produit, veuillez réessayer plus tard
            </p>
          )}
          {isSuccess && (
            <p className={`${styles.signup_info} ${styles.success_msg}`}>
              Veuillez vérifier votre adresse e-mail pour compléter
              l'inscription
            </p>
          )}
        </section>
        <ul className={styles.signin}>
          <li>
            <NavLink to="/login">Se connecter</NavLink>
          </li>
        </ul>
      </form>
    </div>
  );
};
