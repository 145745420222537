import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { InitializeStore } from "./app/initializeStore/InitializeStore";
import { Loader } from "./components/common/loader/Loader";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <InitializeStore />
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
