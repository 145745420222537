import React from 'react'
import styles from './registration.module.css'
import { PageHeader } from '../../components/common/pageHeader/PageHeader'
import { SignUp } from '../../components/signup/SignUp'

export const Registration = () => {
  return (
    <main className={styles.signup_main}>
      <PageHeader title={'Inscription'} />
      <SignUp />
    </main>
  )
}
