import React from 'react'
import { PurchaseDetails } from '../../components/purchase/PurchaseDetails'
import { PageHeader } from '../../components/common/pageHeader/PageHeader'
import styles from './orderDetails.module.css'
export const OrderDetails = () => {
  return (
    <main className={styles.order_details_container}>
      <PageHeader title={'Commande details'} />
      <PurchaseDetails />
    </main>
  )
}
