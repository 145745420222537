import { apiSlice } from '../apiSlice'

export const productsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addProduct: builder.mutation({
      query: (data) => ({
        url: '/todos',
        method: 'POST',
        body: data,
      }),
    }),
    getLandingPageProducts: builder.query({
      query: () => `/products/landing-page`,
    }),
    getProducts: builder.query({
      query: ({ page, size, name, price, category }) =>
        `/products?pageNo=${page}&size=${size}&productName=${name}&maxPrice=${price}&category=${category}`,
      keepUnusedDataFor: 600,
    }),

    getMoreProducts: builder.query({
      query: ({ page, size, name, price, category }) =>
        `/products?pageNo=${page}&size=${size}&productName=${name}&maxPrice=${price}&category=${category}`,
      keepUnusedDataFor: 600,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled
          if (res.data.content?.length > 0) {
            dispatch(
              apiSlice.util.updateQueryData(
                'getProducts',
                {
                  page: 0,
                  size: arg.size,
                  name: arg.name,
                  price: arg.price,
                  category: arg.category,
                },
                (draft) => {
                  draft.content.push(...res.data.content)
                  draft.last = res.data.last
                  draft.number = res.data.number
                }
              )
            )
          }
        } catch (err) {
          // do nothing -
        }
      },
    }),

    getProduct: builder.query({
      query: (productId) => `/products/product/${productId}`,
    }),
  }),
})
export const {
  useGetProductsQuery,
  useGetMoreProductsQuery,
  useGetProductQuery,
  useAddProductMutation,
  useGetLandingPageProductsQuery,
  useCheckPriceAndStockMutation,
} = productsApi
