import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { clearUser } from './user/userSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().user?.info?.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 403) {
    // Attempt to get a new access token using the refresh token
    const refreshResult = await baseQuery(
      '/auth/refresh-token',
      api,
      extraOptions
    )
    if (refreshResult?.data) {
      //store new token -
      api.dispatch({
        type: 'user/setReAuthToken',
        payload: refreshResult.data?.accessToken,
      })
      //retry -
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(clearUser())
    }
  }
  return result
}
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [],
  endpoints: (builder) => ({}),
})
