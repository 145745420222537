import React from 'react'
import { Filter } from '../../components/products/filter/Filter'
import { ProductCart } from '../../components/products/productCart/ProductCart'
import { PageHeader } from '../../components/common/pageHeader/PageHeader'

export const Gents = () => {
  return (
    <>
      <PageHeader title={'Pour les hommes'} />
      <Filter />
      <ProductCart category={'homme'} />
    </>
  )
}
