import React from 'react'
import styles from './suggestedProduct.module.css'
import { Product } from '../../products/product/Product'
import { useGetProductsQuery } from '../../../features/api/products/productsApi'
import { useSelector } from 'react-redux'
import { Loader } from '../../common/loader/Loader'
import { ErrorMessage } from '../../common/errorMessage/ErrorMessage'
import { NoProduct } from '../../products/noProduct/NoProduct'
export const SuggestedProduct = () => {
  const state = useSelector((state) => state.products)
  const {
    data: productPage,
    isLoading,
    isError,
  } = useGetProductsQuery({
    page: 0,
    size: '',
    name: '',
    price: '',
    category: state.selectedCategory,
  })

  //what to render -
  let content = null
  if (isLoading) {
    content = <Loader />
  }
  if (isError) {
    content = <ErrorMessage />
  }
  if (!isError && !isLoading && productPage.content?.length === 0) {
    content = <NoProduct />
  }
  if (!isError && !isLoading && productPage.content?.length > 0) {
    content = (
      <div className={styles.suggested_container}>
        <section className={styles.suggested_text}>
          <h3>by</h3>
          <h6>vous aimerez aussi</h6>
          <p>Redécouvrez votre ingrédient préféré parmi les parfums proposés</p>
          <h3>jfrag</h3>
        </section>
        {productPage.content.map((product,idx) => {
          return <Product product={product} key={idx} />
        })}
      </div>
    )
  }
  return content
}
