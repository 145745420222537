import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from '../features/api/apiSlice'
// import productsSliceReducer from '../features/products/productsSlice'
import basketSlice from '../features/api/basket/basketSlice'
import filterSlice from '../features/filter/filterSlice'
import productsSlice from '../features/api/products/productsSlice'
import { checkPriceAndStockMiddleWare } from '../features/api/middleware/checkPriceAndStockMiddleWare'
import userSlice from '../features/api/user/userSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    basket: basketSlice,
    filters: filterSlice,
    products: productsSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(
      apiSlice.middleware,
      checkPriceAndStockMiddleWare
    ),
})

let previousUser = store.getState().user.info
let previousProductList = store.getState().basket.productList

store.subscribe(() => {
  const state = store.getState()
  const currentUser = state.user.info
  const currentProductList = state.basket.productList

  // Check if user has changed
  if (currentUser !== previousUser) {
    localStorage.setItem('jfragUser', JSON.stringify(currentUser))

    // Clone the object to avoid reference issues
    previousUser = currentUser
  }
  // Check if  productList has changed
  if (currentProductList !== previousProductList) {
    localStorage.setItem('jfragBasket', JSON.stringify(currentProductList))

    // Update previous values to current values
    previousProductList = currentProductList
  }
})
