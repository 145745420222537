import React from 'react'
import styles from './pageHeader.module.css'
import pageHeaderbg from '../../../assests/productCart/prd_cart_header_bg.jpg'
export const PageHeader = ({ title }) => {
  return (
    <div className={styles.pageHeader}>
      <h2 className={styles.pageTitle}>{title}</h2>
      <img src={pageHeaderbg} alt='background flowers' />
    </div>
  )
}
