import React from "react";

export const CGR = () => {
  return (
    <div>
      <h3>RETOURNER UN PRODUIT</h3>
      <div>
        <h4>
          1. Dispositions générales applicables à tous les modes de retour (en
          magasin ou par courrier)
        </h4>
        <li>
          Les produits retournés doivent être en parfait état, non utilisés, et
          dans leur emballage d'origine.
        </li>
        <li>
          Un justificatif du montant de la commande retournée (par exemple, un
          email de confirmation de commande ou une facture) doit être présenté
          pour valider le retour.
        </li>
        <h4>2. Dispositions particulières pour les retours en magasin</h4>
        <li>
          Les retours en magasin sont possibles dans tous les magasins
          partenaires, sous réserve de présentation d'un justificatif du montant
          de la commande.
        </li>
        <li>
          Les retours sont acceptés du lundi au samedi (hors jours fériés), de
          9h à 18h, en fonction des horaires d'ouverture des magasins concernés.
        </li>
        <h4>3. Dispositions particulières pour les retours par courrier</h4>
        <p>
          Si l’Acheteur ou le destinataire de la commande souhaite exercer son
          droit de rétractation en retournant un produit par courrier, il devra
          suivre les étapes suivantes :
        </p>
        <li>
          1. Utiliser l’emballage d’origine du produit pour retourner le(s)
          produit(s).
        </li>
        <li>
          2. Envoyer le colis à l'aide de l’étiquette de retour prépayée
          Colissimo disponible dans son compte en ligne, ou à ses frais, par
          recommandé avec accusé de réception à l'adresse de JFrag.
        </li>
        <p>
          <b>Note :</b> Si l'Acheteur ou le destinataire ne peut prouver qu'il a
          bien déposé le produit auprès d'un transporteur ou dans un magasin,
          tous les risques liés au retour du produit seront à sa charge.
        </p>
      </div>
    </div>
  );
};
