import React from "react";
import styles from "./input.module.css";

export const Input = ({
  label,
  name,
  type = "text",
  register,
  error,
  color = "#000000",
}) => {
  return (
    <div className={styles._input_group} style={{ color: color }}>
      <label htmlFor={name}>
        <span>{label}</span>
        <span>:</span>
      </label>
      <input
        autoComplete="on"
        {...register(name)}
        type={type}
        id={name}
        style={{ borderBottom: `1px solid ${color}`, color: color }}
      />
      {error && <p>{error.message}</p>}
    </div>
  );
};
