import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  info: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      let loggedInUser = {
        accessToken: action.payload?.accessToken,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        email: action.payload?.email,
        phone: action.payload?.phone,
        address: action.payload?.address,
        deliveryAddress: action.payload?.deliveryAddress,
      };
      state.info = action.payload ? loggedInUser : null;
    },
    setReAuthToken: (state, action) => {
      if (state.info != null) state.info.accessToken = action.payload;
    },
    clearUser: (state, action) => {
      state.info = null;
    },
    updateUser: (state, action) => {
      let updatedUserInfo = {
        accessToken: state.info?.accessToken,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        email: action.payload?.email,
        phone: action.payload?.phone,
        address: action.payload?.address,
        deliveryAddress: action.payload?.deliveryAddress,
      };
      state.info = updatedUserInfo;
    },
  },
});

export const { setUser, setReAuthToken, clearUser, updateUser } =
  userSlice.actions;
export default userSlice.reducer;
