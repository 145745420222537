import React from "react";
import styles from "./saticProfile.module.css";
import { useDeleteUserAccountMutation } from "../../features/api/user/userApi";
import { useDispatch } from "react-redux";
import { clearUser } from "../../features/api/user/userSlice";
import { useNavigate } from "react-router-dom";

export const StaticProfile = ({ info, handleEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteUserAccount, { isLoading, isError, isSuccess }] =
    useDeleteUserAccountMutation();

  const deleteAccount = async () => {
    try {
      await deleteUserAccount().unwrap();
      dispatch(clearUser());
      navigate("/");
    } catch (err) {
      alert("Un erreur s'est produite, veuillez réessayer ultérieurement");
    }
  };

  return (
    <div className={styles.profile_container}>
      <section className={styles.profile_info}>
        <p>
          <span>Prénom </span>
          <span>: {info.firstName}</span>
        </p>
        <p>
          <span>Nom </span>
          <span>: {info.lastName}</span>
        </p>
        <p>
          <span>Phone </span>
          <span>: {info.phone}</span>
        </p>
        <button
          className={styles.delete_account}
          onClick={deleteAccount}
          type="button"
        >
          Supprimer
        </button>
      </section>
      <section className={styles.address}>
        <section className={styles.billing_address}>
          <h2>Adresse de facturation</h2>
          <p>
            <span>No </span>
            <span>: {info.address?.doorNo}</span>
          </p>
          <p>
            <span>Complément </span>
            <span>: {info.address?.extra}</span>
          </p>
          <p>
            <span>Rue </span>
            <span>: {info.address?.road}</span>
          </p>
          <p>
            <span>Code postal </span>
            <span>: {info.address?.postCode}</span>
          </p>
          <p>
            <span>Ville </span>
            <span>: {info.address?.district}</span>
          </p>
          <p>
            <span>Pays </span>
            <span>: {info.address?.country}</span>
          </p>
        </section>
        <section className={styles.delivery_address}>
          <h2>Adresse de livraison</h2>
          <p>
            <span>No </span>
            <span>: {info.deliveryAddress?.doorNo}</span>
          </p>
          <p>
            <span>Complément </span>
            <span>: {info.deliveryAddress?.extra}</span>
          </p>
          <p>
            <span>Rue </span>
            <span>: {info.deliveryAddress?.road}</span>
          </p>
          <p>
            <span>Code postal </span>
            <span>: {info.deliveryAddress?.postCode}</span>
          </p>
          <p>
            <span>Ville </span>
            <span>: {info.deliveryAddress?.district}</span>
          </p>
          <p>
            <span>Pays </span>
            <span>: France</span>
          </p>
        </section>
      </section>
      <button
        className={styles.edit_profile}
        onClick={handleEdit}
        type="button"
      >
        Modifier
      </button>
    </div>
  );
};
