import React from 'react'
import styles from './newproducts.module.css'
import { NewProduct } from './newproduct/NewProduct'
import { NoData } from '../../common/noData/NoData'

export const NewProducts = ({ products }) => {
  return (
    <div className={styles.new_cart_container}>
      <h3>nouveautés</h3>
      {products.length ? (
        <div className={styles.new_cart}>
          <section className={styles.new_cart_items}>
            {products[0] ? <NewProduct product={products[0]} /> : null}
            {products[1] ? <NewProduct product={products[1]} /> : null}
            {products[2] ? <NewProduct product={products[2]} /> : null}
            {products[3] ? <NewProduct product={products[3]} /> : null}
          </section>
        </div>
      ) : (
        <NoData title={'produit'} />
      )}
    </div>
  )
}
