import React from 'react'
import { useAuth } from '../../customHooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'

export const ProtectedRouteWithoutLogin = ({ children }) => {
  const location = useLocation()
  const isAuthenticated = useAuth()
  const from = location.state?.from?.pathname || '/'

  return !isAuthenticated ? children : <Navigate to={from} />
}
