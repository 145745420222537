import styles from './basket.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { BasketItem } from './basketItem/BasketItem'
import { useEffect, useMemo } from 'react'
import { checkPriceAndStock } from '../../features/api/products/thunk/checkPriceAndStock'

export const BasketItems = () => {
  const { productList } = useSelector((state) => state.basket)
  const dispatch = useDispatch()
  // Memoize the product IDs to prevent unnecessary recalculations
  const productIds = useMemo(
    () => productList.map((product) => product.id),
    [productList]
  )

  useEffect(() => {
    // Function to dispatch the checkPriceAndStock thunk
    const updateProductPricesAndStocks = () => {
      dispatch(checkPriceAndStock(productIds))
    }

    // Initial call to update prices and stocks
    updateProductPricesAndStocks()

    // Set up the interval to call the thunk every 60 seconds
    const intervalId = setInterval(() => {
      updateProductPricesAndStocks()
    }, 120000)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [dispatch, productIds])
  return (
    <div className={styles.item_container}>
      <div className={styles.item_header}>
        <h3>Description</h3>
        <h3>Taille</h3>
        <h3>Quantité</h3>
        <h3>Supprimer</h3>
        <h3>Prix/Unité</h3>
      </div>
      <div className={styles.items}>
        {productList.map((product, idx) => (
          <BasketItem key={idx} item={product} />
        ))}
      </div>
    </div>
  )
}
