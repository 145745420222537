import React, { useEffect, useState } from "react";
import styles from "./singleProduct.module.css";
import bg_1 from "../../assests/singleProduct/bg_1.png";
import { Rating } from "../rating/Rating.jsx";
import { useGetProductQuery } from "../../features/api/products/productsApi.js";
import { addProductToBasket } from "../../features/api/basket/basketSlice.js";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import getDiscountPrice from "../../utils/calculateDiscountPrice.js";
import { setCategory } from "../../features/api/products/productsSlice.js";
import { Loader } from "../common/loader/Loader.jsx";
import { ErrorMessage } from "../common/errorMessage/ErrorMessage.jsx";
import { NoProduct } from "../products/noProduct/NoProduct.jsx";

export const SingleProduct = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const { data: product, isLoading, isError } = useGetProductQuery(productId);
  const [quantity, setQuantity] = useState(0);
  const [isValidQuantity, setIsValidQuantity] = useState(true);
  const [price, setPrice] = useState(product?.details[0].price);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [displayPhoto, setDisplayPhoto] = useState("");
  const [itemAdded, setItemAdded] = useState(false);
  let intervalId = null;
  const handleDisplayPhotoChange = (photo) => {
    setDisplayPhoto(photo);
  };
  const handlePriceChange = (detailsId) => {
    if (!detailsId) {
      setPrice(0);
      return;
    }
    let details = product.details.find((data) => data.id === Number(detailsId));
    setQuantity(0);
    setPrice(getDiscountPrice(details?.price, details?.reduction));
    setSelectedDetails(details);
  };
  const handleQuantityChange = (quantity) => {
    if (quantity && selectedDetails.quantity < quantity) {
      setIsValidQuantity(false);
    } else {
      setQuantity(quantity);
      setIsValidQuantity(true);
    }
  };
  const addToCart = () => {
    let item = {
      productId: product.id,
      id: selectedDetails.id,
      size: selectedDetails.size,
      name: product.name,
      price,
      qty: Number(quantity),
      image: product.imageDto.imageB,
    };
    dispatch(addProductToBasket(item));
    setItemAdded(true);
    intervalId = setInterval(() => {
      setItemAdded(false);
    }, 1000);
  };
  useEffect(() => {
    // Cleanup on component unmount or when `addToCart` is called again
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (product) {
      handleDisplayPhotoChange(product.imageDto.imageA);
      dispatch(setCategory(product.category));
    }
  }, [product, dispatch]);
  //what to render -

  let addButtonText = "Ajouter au panier";

  if (!quantity || !price) {
    addButtonText = "Choisissez la taille & quantité";
  } else if (itemAdded && quantity === 1) {
    addButtonText = "Item ajouté au panier";
  } else if (itemAdded) {
    addButtonText = "Items ajoutés au panier";
  }

  let content = null;
  if (isLoading) {
    content = <Loader />;
  }
  if (!isLoading && isError) {
    content = <ErrorMessage />;
  }
  if (!isError && !isLoading && !product) {
    content = <NoProduct />;
  }
  if (!isError && !isLoading && product) {
    content = (
      <div className={styles.product_container}>
        <div className={styles.product}>
          <section className={styles.photo}>
            <section className={styles.size_category}>
              <span></span>
              <p>{product?.details[0]?.size || "100"}ml</p>
              <span></span>
              <p>{product?.category}</p>
              <span></span>
            </section>
            <section className={styles.background}>
              <img src={bg_1} alt="Fleuves" />
            </section>
            <section className={styles.foreground}>
              <img
                src={`data:image/jpeg;base64,${displayPhoto}`}
                alt="Fleuves"
              />
            </section>
          </section>
          <section className={styles.details}>
            <section className={styles.name_price}>
              <h3>
                <span>{product.name}</span>
                <span>{price}€</span>
              </h3>
              <hr />

              {/* ************* to do - to be changed later *********** */}
              <p>{product.shortDescription}</p>
            </section>
            <Rating rate={3} />
            {/********** to do - to be changed later ends *********/}

            <section className={styles.input_group}>
              <div className={styles.size}>
                <label htmlFor="size">Taille</label>
                <br />
                <select
                  name="size"
                  id="size"
                  onChange={(e) => handlePriceChange(e.target.value)}
                >
                  <option value="">Choisissez la taille</option>
                  {product.details.map((data) => {
                    return (
                      <option key={data.size} value={data.id}>
                        {data.size} ml
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={styles.quantity}>
                <label htmlFor="quantity">Quantité</label>
                <br />
                <input
                  disabled={!selectedDetails}
                  type="number"
                  onChange={(e) => handleQuantityChange(e.target.value)}
                  onClick={() => setQuantity("")}
                  value={quantity}
                />
                {!isValidQuantity && (
                  <p className={styles.warning}>
                    Nous avons {selectedDetails?.quantity} en stock
                  </p>
                )}
              </div>
              <div>
                <button
                  type="button"
                  disabled={!quantity || !price || itemAdded}
                  onClick={addToCart}
                >
                  {addButtonText}
                </button>
              </div>
            </section>
            <p className={styles.ingredient}>{product.ingredient}</p>
            <div className={styles.thumbnail}>
              <div
                onClick={() =>
                  handleDisplayPhotoChange(product.imageDto.imageA)
                }
              >
                <img
                  src={`data:image/jpeg;base64,${product.imageDto.imageA}`}
                  alt="thumbnail"
                />
              </div>
              <div
                onClick={() =>
                  handleDisplayPhotoChange(product.imageDto.imageB)
                }
              >
                <img
                  src={`data:image/jpeg;base64,${product.imageDto.imageB}`}
                  alt="thumbnail"
                />
              </div>
            </div>
          </section>
        </div>
        <section className={styles.description}>
          <p>{product.description}</p>
        </section>
      </div>
    );
  }
  return content;
};
