import React from 'react'
import styles from '../trendingproducts/trendingproducts.module.css'
import { TrendingProduct } from './trendingProduct/TrendingProduct'
import { NoData } from '../../common/noData/NoData'

export const TrendingProducts = ({ products }) => {
  return (
    <div className={styles.trend_cart_container}>
      {products.length ? (
        <div className={styles.trend_cart}>
          <p className={styles.ltr_a}>A</p>
          <section className={styles.top_midle}>
            {products[0] ? <TrendingProduct product={products[0]} />: null}
          </section>
          <section className={styles.top_right}>
            {products[1] ? <TrendingProduct product={products[1]} />: null}
          </section>
          <p className={styles.ltr_la}>LA</p>
          <section className={styles.bottom_left}>
            {products[2] ? <TrendingProduct product={products[2]} />: null}
          </section>
          <section className={styles.bottom_midle}>
            {products[3] ? <TrendingProduct product={products[3]} />: null}
          </section>
          <p className={styles.ltr_mode}>MODE</p>
        </div>
      ) : (
        <NoData title={'produit'} />
      )}
    </div>
  )
}
