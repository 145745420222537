import React from "react";
import styles from "./purchase.module.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { orderApi, useGetOrdersQuery } from "../../features/api/order/orderApi";
import { useDispatch } from "react-redux";
import { Loader } from "../common/loader/Loader";
import { NoData } from "../common/noData/NoData";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../common/errorMessage/ErrorMessage";

const dateTimeFormatter = (datetimeStr) => {
  const datetime = new Date(datetimeStr);

  // Format the date and time
  return datetime.toLocaleDateString();
};
export const Purchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: orderPage,
    isLoading,
    isError,
  } = useGetOrdersQuery({
    page: 0,
    size: 10,
  });
  const getNextPage = () => {
    dispatch(
      orderApi.endpoints.getMoreOrder.initiate({
        page: orderPage?.number + 1,
        size: 10,
      })
    );
  };
  const getOrderDetails = (orderId) => {
    navigate(`/purchase-details/${orderId}`);
  };

  //what to render -
  let content = null;
  if (isLoading) {
    content = <Loader />;
  }
  if (isError) {
    content = <ErrorMessage />;
  }
  if (!isError && !isLoading && orderPage.content?.length === 0) {
    content = <NoData title2={"Commande"} />;
  }
  if (!isError && !isLoading && orderPage.content?.length > 0) {
    content = (
      <>
        <Table className={styles.order_tbl}>
          <Thead>
            <Tr>
              <Th>Commande</Th>
              <Th>Date</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody className={styles.order_tbl_tbody}>
            {orderPage.content.map((data) => (
              <Tr
                key={data.id}
                className={styles.order_body_row}
                onClick={() => getOrderDetails(data.id)}
              >
                <Td>{data.orderRef}</Td>
                <Td>{dateTimeFormatter(data.createdAt)}</Td>
                <Td>
                  {/* {data.status === 'PAID'
                    ? 'En cours de préparation'
                    : 'Envoyé'} */}
                  {data.status === "PAID"
                    ? "En cours de préparation"
                    : data.status === "CANCEL"
                    ? "Annulée"
                    : "Enovoyée"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {!orderPage?.last && (
          <section className={styles.btn_container}>
            <button className={styles.btn_see_more} onClick={getNextPage}>
              Voir plus de commande
            </button>
          </section>
        )}
      </>
    );
  }
  return content;
};
