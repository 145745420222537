import React from "react";
import { PageHeader } from "../../components/common/pageHeader/PageHeader";
import { Verification } from "../../components/verfication/Verification";
import styles from "./accountVerification.module.css";

export const AccountVerification = () => {
  return (
    <main>
      <PageHeader title={"Vérification du compte"} />
      <div className={styles.verification_container}>
        <Verification />
      </div>
    </main>
  );
};
