import { apiSlice } from '../../apiSlice'

export const paymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    prepareOrder: builder.mutation({
      query: (data) => ({
        url: '/orders/restricted/prepare-order',
        method: 'POST',
        body: data,
      }),
    }),
    placeOrder: builder.mutation({
      query: (data) => ({
        url: '/orders/restricted/place-order',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response) => {
        // Check if response is JSON or plain text
        if (typeof response === 'string') {
          return { message: response }
        }
        return response
      },
    }),
  }),
})

export const { usePrepareOrderMutation, usePlaceOrderMutation } = paymentApi
