import { PageHeader } from '../../components/common/pageHeader/PageHeader'
import { SignIn } from '../../components/signIn/SignIn'
import styles from './login.module.css'

import React from 'react'

export const Login = () => {
  return (
    <main className={styles.login_main}>
      <PageHeader title={'Connexion'} />
      <SignIn />
    </main>
  )
}
