import { apiSlice } from "../apiSlice";

export const orderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ page, size }) =>
        `/orders/restricted?pageNo=${page}&size=${size}`,
    }),
    getMoreOrder: builder.query({
      query: ({ page, size }) =>
        `/orders/restricted?pageNo=${page}&size=${size}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          if (res.data.content?.length > 0) {
            dispatch(
              apiSlice.util.updateQueryData(
                "getOrders",
                { page: 0, size: arg.size },
                (draft) => {
                  draft.content.push(...res.data.content);
                  draft.last = res.data.last;
                  draft.number = res.data.number;
                }
              )
            );
          }
        } catch (err) {
          // do nothing -
        }
      },
    }),
    getOrderByIdAndUser: builder.query({
      query: (orderId) => `/orders/restricted/order?orderId=${orderId}`,
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetMoreOrderQuery,
  useGetOrderByIdAndUserQuery,
} = orderApi;
