import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  products: [],
  pageNo: 0,
  selectedCategory: '',
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      state.products.push(action.payload.todo)
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload
    },
    setCategory: (state, action) => {
      state.selectedCategory = action.payload
    },
  },
})

export const { addProduct, setPageNo, setCategory } = productsSlice.actions
export default productsSlice.reducer
