import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./basketItem.module.css";
import getDiscountPrice from "../../../utils/calculateDiscountPrice";
import {
  decreaseProductQuantity,
  increaseProductQuantity,
  removeProductFromBasket,
} from "../../../features/api/basket/basketSlice";
import { Link } from "react-router-dom";

export const BasketItem = ({ item }) => {
  const dispatch = useDispatch();

  const increase = (id) => {
    dispatch(increaseProductQuantity(id));
  };
  const decrease = (id) => {
    dispatch(decreaseProductQuantity(id));
  };
  const remove = (id) => {
    dispatch(removeProductFromBasket(id));
  };
  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <div className={styles.basket_item}>
      <section className={styles.item_description}>
        <h4>{item.name}</h4>
        <Link to={`/product/${item.productId}`}>
          <img src={`data:image/jpeg;base64,${item.image}`} alt="Perfume" />
        </Link>
      </section>
      <section className={styles.item_size}>
        <p>{item.size} ML</p>
      </section>
      {item.inStock > 0 && (
        <section className={styles.item_quantity}>
          <div>
            <button
              onClick={() => increase(item.id)}
              disabled={item.qty > item.inStock}
            >
              +
            </button>
            <p>{item.qty}</p>
            <button onClick={() => decrease(item.id)}>-</button>
          </div>
          {item.qty > item.inStock && (
            <p className={styles.stock_warning}>
              Nous avons que {item.inStock} en stock
            </p>
          )}
        </section>
      )}
      {!item.inStock && (
        <section className={styles.item_quantity}>
          <p>Rupture de stock</p>
        </section>
      )}
      <section>
        <button onClick={() => remove(item.id)} className={styles.item_delete}>
          X
        </button>
      </section>
      <section className={styles.item_price}>
        {!item.reduction && <p>{item.price}€</p>}
        {item.reduction > 0 && (
          <div>
            <p>{item.price}€</p>
            <p>-{item.reduction}%</p>
            <p>{getDiscountPrice(item.price, item.reduction)}</p>
          </div>
        )}
      </section>
    </div>
  );
};
