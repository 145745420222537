import React, { useEffect, useState } from "react";
import styles from "./updatePasswordRequest.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import * as v from "valibot";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useUpdatePasswordMutation } from "../../features/api/user/userApi";
import { Input } from "../common/input/Input";

const schema = v.pipe(
  v.object({
    password: v.pipe(
      v.string(),
      v.regex(/[A-Z]/, "Doit comporter au moins une lettre majuscule"), // At least one uppercase letter
      v.regex(/[a-z]/, "Doit comporter au moins une lettre minuscule"), // At least one lowercase letter
      v.regex(/[0-9]/, "Doit comporter au moins un chiffre"), // At least one number
      v.regex(
        /[!@#$%^&*(),.?":{}|<>+-]/,
        "Doit comporter au moins un caractère spécial"
      ), // At least one special character
      v.minLength(8, "Doit comporter au moins 8 caractères")
    ),
    passwordConfirmation: v.pipe(v.string()),
  }),
  v.forward(
    v.partialCheck(
      [["password"], ["passwordConfirmation"]],
      (input) => input.password === input.passwordConfirmation,
      "Les mots de passe ne correspondent pas" // Ensure passwords match"
    ),
    ["passwordConfirmation"]
  )
);

export const UpdatePasswordRequest = () => {
  const [token, setToken] = useState(null);
  const [serverErr, setServerErr] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [tokenMissing, setTokenMissing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: valibotResolver(schema),
  });
  const navigate = useNavigate();

  const [updatePassword, { isLoading, isSuccess }] =
    useUpdatePasswordMutation();

  const onSubmit = async (userInfo) => {
    if (!token) {
      tokenMissingErr();
    } else {
      try {
        let data = { password: userInfo.password };
        await updatePassword({ data, token }).unwrap();
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      } catch (err) {
        if (err?.data?.errorCode === 400) {
          tokenExpiredErr();
        } else {
          serverError();
        }
      }
    }
  };
  const tokenMissingErr = () => {
    setTokenMissing(true);
    setTimeout(() => {
      setTokenMissing(false);
      navigate("/login");
    }, 5000);
  };
  const tokenExpiredErr = () => {
    setTokenExpired(true);
    setTimeout(() => {
      setTokenExpired(false);
      navigate("/login");
    }, 3000);
  };
  const serverError = () => {
    setTokenExpired(true);
    setTimeout(() => {
      setServerErr(false);
      navigate("/login");
    }, 3000);
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get("token");
    setToken(tokenParam);
    if (!tokenParam) tokenMissingErr();
  }, []);
  return (
    <div className={styles.update_password_container}>
      <section className={styles.triangle_container}>
        <div></div>
        <div></div>
        <div></div>
        <p>J</p>
      </section>
      <form
        className={styles.update_password_form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <section>
          <Input
            label="Mot de passe"
            name="password"
            type="password"
            register={register}
            error={errors.password}
            color="#ffffff"
          />{" "}
          <Input
            label="Confirmation"
            name="passwordConfirmation"
            type="password"
            register={register}
            error={errors.passwordConfirmation}
            color="#ffffff"
          />
        </section>
        <section className={styles.submit_btn_info}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "En cour..." : "Mettre à jour"}
          </button>
          {tokenExpired && (
            <p className={`${styles.update_info} ${styles.err_msg}`}>
              Le lien de réinitialisation du mot de passe a expiré ou est
              invalide
            </p>
          )}

          {serverErr && (
            <p className={`${styles.update_info} ${styles.err_msg}`}>
              Un problème s'est produit, veuillez réessayer plus tard
            </p>
          )}
          {tokenMissing && (
            <p className={`${styles.update_info} ${styles.err_msg}`}>
              Le lien de réinitialisation du mot de passe est invalide
            </p>
          )}
          {isSuccess && (
            <p className={`${styles.update_info} ${styles.success_msg}`}>
              Le mot de passe a été mis à jour avec succès
            </p>
          )}
        </section>
        <ul className={styles.redirect_btn_group}>
          <li>
            <NavLink to="/login">Se connecter</NavLink>
          </li>
          <li>
            <NavLink to="/sign-up">S'inscrire</NavLink>
          </li>
        </ul>
      </form>
    </div>
  );
};
