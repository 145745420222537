import React from "react";
import { UpdatePasswordRequest } from "../../components/updatePasswordRequest/UpdatePasswordRequestRequest";
import { PageHeader } from "../../components/common/pageHeader/PageHeader";

export const UpdatePassword = () => {
  return (
    <main>
      <PageHeader title={"Mettre à jour du mot de passe"} />
      <UpdatePasswordRequest />
    </main>
  );
};
