import { url } from "valibot";
import { apiSlice } from "../apiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    logIn: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data,
      }),
    }),
    signUp: builder.mutation({
      query: (data) => ({
        url: "/users/register",
        method: "POST",
        body: data,
      }),
    }),
    accountVerification: builder.mutation({
      query: (token) => ({
        url: `/users/register/verify-email?token=${token}`,
        method: "GET",
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: "/users/restricted/update-user",
        method: "PUT",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "/users/reset-password",
        method: "POST",
        body: data,
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ data, token }) => ({
        url: `/users/update-password?token=${token}`,
        method: "POST",
        body: data,
      }),
    }),
    deleteUserAccount: builder.mutation({
      query: () => ({
        url: `/users/restricted/delete-user`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useLogInMutation,
  useSignUpMutation,
  useUpdateUserMutation,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
  useDeleteUserAccountMutation,
  useAccountVerificationMutation,
} = userApi;
