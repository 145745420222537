import {
  addProductToBasket,
  increaseProductQuantity,
  setProductList,
} from '../basket/basketSlice'
import { checkPriceAndStock } from '../products/thunk/checkPriceAndStock'

export const checkPriceAndStockMiddleWare = (store) => (next) => (action) => {
  if (
    action.type === setProductList.type ||
    action.type === addProductToBasket.type ||
    action.type === increaseProductQuantity.type
  ) {
    const result = next(action) // Allow the action to proceed
    const state = store.getState()
    const productIds = state.basket.productList.map((product) => product.id)

    // Dispatch the fetchProductPrices thunk with the extracted product IDs
    if (productIds.length > 0) {
      store.dispatch(checkPriceAndStock(productIds))
      return result
    }
  }
  return next(action)
}
