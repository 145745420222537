import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import styles from "./payment.module.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { usePrepareOrderMutation } from "../../features/api/order/payment/paymentApi";
import CheckoutForm from "./CheckoutForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ItemToPay } from "./ItemToPay";
import arrowIcon from "../../assests/icons/up-arrow-svgrepo-com.svg";
import { Loader } from "../common/loader/Loader";

export const Payment = () => {
  const navigate = useNavigate();
  const { productList } = useSelector((state) => state.basket);
  const { total } = useSelector((state) => state.basket);
  const { accessToken } = useSelector((state) => state.user.info);
  const [order, setOrder] = useState({});

  const [prepareOrder] = usePrepareOrderMutation();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    if (productList.length > 0 && accessToken) {
      const orderList = productList.map((element) => ({
        productDetailId: element.id,
        quantity: element.qty,
      }));
      let decoded = jwtDecode(accessToken);
      setOrder({
        userEmail: decoded.sub,
        orderDetailsRequestDtos: orderList,
      });
    }
  }, [productList, accessToken]);
  const prepare = useCallback(async () => {
    try {
      let response = await prepareOrder(order).unwrap();
      let { clientSecret } = response;
      setClientSecret(clientSecret);
    } catch (err) {
      if (err.status === 412) navigate("/basket");
    }
  }, [navigate, order, prepareOrder]);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY));
  }, []);

  useEffect(() => {
    if (order.userEmail && order.orderDetailsRequestDtos.length) {
      prepare();
    }
  }, [order, prepare]);

  const appearance = {
    theme: "stripe",
    variables: {
      fontWeightNormal: "500",
      borderRadius: "2px",
      colorPrimary: "#f360a6",
      tabIconSelectedColor: "#fff",
      gridRowSpacing: "16px",
    },
    rules: {
      ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
        boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
      },
      ".Block": {
        borderColor: "transparent",
      },
      ".BlockDivider": {
        backgroundColor: "#ebebeb",
      },
      ".Tab, .Tab:hover, .Tab:focus": {
        border: "0",
      },
      ".Tab--selected, .Tab--selected:hover": {
        backgroundColor: "#f360a6",
        color: "#fff",
      },
    },
  };

  let content = null;
  if (!clientSecret || !stripePromise) content = <Loader />;
  if (clientSecret && stripePromise)
    content = (
      <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
        <CheckoutForm order={order} />
      </Elements>
    );
  return (
    <div className={styles.payment}>
      <section className={styles.order_summery}>
        <section className={styles.order_summery_items}>
          <button
            className={styles.btn_basket}
            onClick={() => navigate("/basket")}
          >
            <img src={arrowIcon} alt="Retour au panier" />
            Retour au panier
          </button>
          <h2>Récapitulatif de votre commande </h2>
        </section>
        {productList.map((item, idx) => (
          <ItemToPay key={idx} item={item} />
        ))}
        <p className={styles.order_summery_total}>Total : {total}</p>
      </section>
      <section>
        <h2> Paiement</h2>
        {content}
      </section>
    </div>
  );
};
