import React from 'react'
import styles from './basket.module.css'
import { BasketItems } from '../../components/basketItems/BasketItems'
import { AddressPayment } from '../../components/addressPayment/AddressPayment'
import { useSelector } from 'react-redux'
import { PageHeader } from '../../components/common/pageHeader/PageHeader'

export const Basket = () => {
  const { itemCount } = useSelector((state) => state.basket)
  let content = null
  if (itemCount) {
    content = (
      <section className={styles.basket_section}>
        <BasketItems></BasketItems>
        <AddressPayment></AddressPayment>
      </section>
    )
  } else {
    content = (
      <div className={styles.empty_basket}>
        <p>Votre panier est vide!</p>
      </div>
    )
  }

  return (
    <main className={styles.main}>
      <PageHeader title={'Mon panier'} />
      {content}
    </main>
  )
}
