import { PaymentElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { usePlaceOrderMutation } from '../../features/api/order/payment/paymentApi'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearBasket } from '../../features/api/basket/basketSlice'
import styles from './checkoutForm.module.css'

export default function CheckoutForm({ order }) {
  const [placeOrder] = usePlaceOrderMutation()
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [message, setMessage] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    // const { id, error, status } = await stripe.confirmPayment({
    const { paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `${window.location.origin}/completion`,
        // return_url: 'http://localhost:3000',
      },
      redirect: 'if_required',
    })
    if (!paymentIntent) {
      setMessage('Un problème est survenu lors du paiement')
      return
    }
    setIsProcessing(true)
    const { id, status, error } = paymentIntent

    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error.message)
    } else if (error) {
      setMessage('Un problème est survenu lors du paiement')
    } else if (status === 'succeeded') {
      confirmOrder(id)
    }
    setIsProcessing(false)
  }

  const confirmOrder = async (paymentId) => {
    try {
      order.paymentId = paymentId
      await placeOrder(order).unwrap()
      dispatch(clearBasket())
      navigate('/order-success')
    } catch (error) {
      setMessage('Un problème est survenu lors du paiement')
    }
  }

  return (
    <form
      id='payment-form'
      onSubmit={handleSubmit}
      className={styles.checkout_form}
    >
      <PaymentElement id='payment-element' />
      {elements && stripe && (
        <button disabled={isProcessing} id='submit'>
          <span id='button-text'>
            {isProcessing ? 'En cours ... ' : 'Payer'}
          </span>
        </button>
      )}
      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
    </form>
  )
}
