import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setProductList } from '../../features/api/basket/basketSlice'
import { setUser } from '../../features/api/user/userSlice'

export const InitializeStore = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const savedProducts = JSON.parse(localStorage.getItem('jfragBasket')) || []
    const loggedInUser = JSON.parse(localStorage.getItem('jfragUser')) || null
    dispatch(setProductList(savedProducts))
    dispatch(setUser(loggedInUser))
  }, [dispatch])

  return null
}
