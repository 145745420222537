import React from 'react'
import { Hero } from '../../components/landing/hero/Hero'
import { NewProducts } from '../../components/landing/newproducts/NewProducts'
import { PromoProducts } from '../../components/landing/promoproducts/PromoProducts'
import { TrendingProducts } from '../../components/landing/trendingproducts/TrendingProducts'
import { useGetLandingPageProductsQuery } from '../../features/api/products/productsApi'
import { Loader } from '../../components/common/loader/Loader'
import { ErrorMessage } from '../../components/common/errorMessage/ErrorMessage'
import { NoData } from '../../components/common/noData/NoData'

export const Home = () => {
  const {
    data: productList,
    isLoading,
    isError,
  } = useGetLandingPageProductsQuery()

  let content = null
  if (isLoading) {
    content = <Loader />
  }
  if (isError) {
    content = <ErrorMessage />
  }
  if (productList) {
    content = (
      <>
        <NewProducts products={productList?.newProductDtos} />
        <PromoProducts products={productList?.discountProductDtos} />
        <TrendingProducts products={productList?.trendingProductDtos} />
      </>
    )
  } else {
    content = <NoData title={'produit'} />
  }

  return (
    <>
      <Hero />
      {content}
    </>
  )
}
