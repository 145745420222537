import { createAsyncThunk } from "@reduxjs/toolkit";

export const checkPriceAndStock = createAsyncThunk(
  "basket/checkProductsPricesAndStocks",
  async (productDetailIds) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/details/check-price-stock`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productDetailIds),
      }
    );
    let data = await response.json();
    return data;
  }
);
