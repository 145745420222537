import React from "react";

export const Mentionlegal = () => {
  return (
    <div>
      <h3>MENTIONS LEGALES</h3>
      <section>
        Editeur : Le site JFrag est produit et édité par JFrag SAS, société par
        actions simplifiée au capital de 00 000 000 euros, immatriculée au
        Registre du Commerce et des Sociétés de Nanterre sous le numéro B 000
        000 000, dont le siège social est situé au :
      </section>
      <section>
        7 Av, Paul Cézanne, 95200 Sarcelles, France. Téléphone : 00 00 00 00 00
        E-mail : service@jfrag.com Directeur de publication : Mohammed Mahfujul
        Haque Responsable de la rédaction : Mohammed Mahfujul Haque N°
        individuel de TVA : FR 00 000 000 000
      </section>
      <b>SIRET : 985 334 614 00015</b>
      <p>
        Site hébergé par la société xxxx.com France SAS dont le siège social est
        3 Avenue Octave Gréard 75007 Paris et le numéro de téléphone est 00 00
        00 00 00.
      </p>
    </div>
  );
};
