import React from 'react'
import styles from './noProduct.module.css'

export const NoProduct = () => {
  return (
    <div className={styles.no_product_container}>
      <h3>Aucun produit trouvé</h3>
    </div>
  )
}
