import React from "react";
import styles from "./product.module.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const Product = ({ product }) => {
  const {
    id,
    name,
    shortDescription,
    category,
    price,
    size,
    reduction,
    cartImage,
  } = product;
  return (
    <motion.div
      layout
      className={styles.cart_item}
      animate={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Link to={`/product/${id}`}>
        <div className={styles.link_wrapper}>
          <figcaption>
            <h4>{name}</h4>
            <section>
              <p>{shortDescription}</p>
              <p>
                <span>{category}</span>
                <span>|</span>
                <span>{size}ML</span>
              </p>
            </section>
          </figcaption>
          <section>
            <img src={`data:image/jpeg;base64,${cartImage}`} alt="perfume" />
          </section>
          <h5>
            <span>{price}€</span>
            {reduction > 0 && <span> | -{reduction}%</span>}
          </h5>
        </div>
      </Link>
    </motion.div>
  );
};
