import React, { useState } from 'react'
import { PageHeader } from '../../components/common/pageHeader/PageHeader'
import styles from './account.module.css'
import { Profile } from '../../components/profile/Profile'
import { Purchase } from '../../components/purchase/Purchase'

export const Account = () => {
  const [activeMenu, setActiveMenu] = useState('purchase')

  const handleToggle = (menu) => {
    setActiveMenu(menu)
  }

  let content = null
  if (activeMenu === 'profile') content = <Profile />
  if (activeMenu === 'purchase') content = <Purchase />
  return (
    <main className={styles.account_main}>
      <PageHeader title={'Mon compte'} />
      <ul className={styles.account_menu}>
        <li
          onClick={() => handleToggle('purchase')}
          className={activeMenu === 'purchase' ? styles.active : ''}
        >
          Mes achats
        </li>
        <li
          onClick={() => handleToggle('profile')}
          className={activeMenu === 'profile' ? styles.active : ''}
        >
          Mon profile
        </li>
      </ul>
      {content}
    </main>
  )
}
