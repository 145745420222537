import React from "react";

export const CG = () => {
  return (
    <div>
      <h3>CONTACT GENERALES </h3>
      <h4>1. Coordonnées Générales :</h4>
      <li>Email général : contact@jfrag.com</li>
      <li>Numéro de téléphone : +33 XX XX XX XX XX </li>
      <li>Adresse postale : xxxxx</li>

      <h4>2. Support Client :</h4>
      <li>Email du support : support@jfrag.com</li>
      <li>Numéro pour le support : +33 XX XX XX XX XX </li>

      <h4>3. Horaires de contact :</h4>
      <li>Lundi au Vendredi : 9h00 - 18h00</li>
      <li>Fermé le weekend </li>

      <h4>4. Formulaire de Contact :</h4>
      <li>
        Un lien vers un formulaire de contact en ligne, si tu veux que les gens
        soumettent des requêtes via ton site web directement.
      </li>

      <h4>5. Réseaux Sociaux :</h4>
      <li>
        Lien vers les pages Twitter, LinkedIn, Facebook ou autres plateformes où
        JFRAG est actif.
      </li>
    </div>
  );
};
