import React, { useState } from 'react'
import { EditableProfile } from './EditableProfile'
import { useSelector } from 'react-redux'
import { StaticProfile } from './StaticProfile'

export const Profile = () => {
  const [isEditing, setIsEditing] = useState(false)
  const handleEdit = () => {
    setIsEditing(!isEditing)
  }
  const info = useSelector((state) => state.user.info)
  let content = isEditing ? (
    <EditableProfile info={info} handleEdit={handleEdit} />
  ) : (
    <StaticProfile info={info} handleEdit={handleEdit} />
  )
  return content
}
