import React from "react";

export const CGV = () => {
  return (
    <div>
      <h3> CONDITIONS GENERALES DE VENTE</h3>
      <section className="">
        <p>
          Aux fins des présentes Conditions Générales de Vente (ci-après « CGV »
          ou « Conditions Générales de Vente »), l'Acheteur et JFrag sont
          individuellement ou collectivement désignés la « Partie » ou les «
          Parties ». En tant que membre d'un réseau de distribution sélective,
          JFrag vend les produits présents sur son site de vente à distance
          uniquement au détail et à des consommateurs finaux. Ainsi, les achats
          réalisés sur le Site sont réservés à une clientèle de particuliers non
          professionnels pour leur usage propre (ou à des fins de cadeaux dans
          le cercle de famille). En conséquence, l'Acheteur, préalablement à sa
          Commande, déclare que l'achat de produits sur le Site est sans rapport
          direct avec une activité professionnelle et est limité à une
          utilisation strictement personnelle. JFrag pourra annuler ou refuser
          toute Commande ne correspondant pas à une vente au détail dans les
          conditions prévues.
        </p>
        <h4>COMMANDE</h4>
        <p>
          Avant de finaliser sa Commande, l’Acheteur a la possibilité de
          vérifier les détails de son projet de Commande et de corriger toute
          erreur éventuelle. Il doit également accepter expressément les CGV,
          qu’il peut télécharger et conserver, en cochant la case prévue à cet
          effet.
        </p>
        <p>
          Le contrat de vente à distance est formé lorsque l'acheteur confirme
          sa Commande en cliquant sur le bouton « Payer » (ci-après et ci-avant
          la « Commande »). En procédant ainsi, l'Acheteur accepte en toute
          connaissance de cause le contenu et les conditions de la commande,
          notamment l'obligation de paiement, les prix, quantités,
          caractéristiques, volumes et délais de livraison des produits
          commandés.
        </p>
        <p>
          Après cette validation, l’Acheteur ne pourra plus annuler sa Commande.
          Toutefois, pour les Commandes effectuées exclusivement sur le site
          internet accessible à l’adresse www.jfrag.fr, l’Acheteur dispose de 15
          minutes suivant la validation de son paiement pour annuler sa Commande
          en cliquant sur le bouton « annuler ma commande » disponible sur la
          page de confirmation de commande ou dans son compte. Cette annulation
          concerne la Commande dans son intégralité, sans possibilité
          d’annulation partielle. La vente sera alors considérée comme
          définitive, sous réserve de l’exercice du droit de rétractation par
          l’Acheteur selon les conditions prévues à l’article 6 des présentes
          CGV. L’Acheteur recevra une Confirmation de Commande par tout moyen
          approprié, et un courrier électronique sera envoyé lors de
          l’expédition de la commande.
        </p>
        <p>
          Pour les Commandes en « Click & Collect » ou livrées par coursier, le
          courrier électronique confirmant la disponibilité de la Commande fait
          office à la fois de Confirmation de Commande et de notification
          d’expédition.
        </p>
        <h4>DROIT DE RÉTRACTATION </h4>
        <p>
          Conformément à l'article L. 221-18 du Code de la Consommation,
          l'Acheteur dispose d'un délai de quatorze (14) jours à compter de la
          réception du colis contenant les produits commandés, pour exercer son
          droit de rétractation sans avoir à justifier sa décision. Conformément
          à l'article L.221-28 du Code de la Consommation, ce droit ne peut pas
          être exercé pour les commandes concernant :
        </p>
        <li>
          La fourniture de biens descellés après livraison et ne pouvant être
          retournés pour des raisons d'hygiène ou de protection de la santé,
        </li>
        <li>
          La fourniture de biens confectionnés selon les spécifications du
          consommateur ou nettement personnalisés (notamment les services
          relatifs à « l’atelier gravure » ne peuvent faire l’objet du droit de
          rétractation).
        </li>
        <p>
          Ces dispositions s'appliquent sans préjudice des garanties prévues à
          l'article 7 des présentes CGV, qui demeurent pleinement applicables.
        </p>
        <p>
          L'Acheteur exerçant son droit de rétractation dans les conditions
          prévues par le présent article, en termes de délai et de modalités de
          retour des produits, pourra obtenir un remboursement des produits
          retournés ainsi que des frais de livraison (au coût standard en cas de
          retour total de la commande), conformément à l’article L221-24 du Code
          de la Consommation. Selon l’article L221-23 du Code de la
          Consommation, JFrag prend en charge les frais de retour depuis la
          France métropolitaine, via l’étiquette de retour prépayée Colissimo
          disponible dans le compte en ligne de l’acheteur, à l’exclusion de
          tout autre mode d’expédition des produits, dont les frais resteront à
          la charge de l’acheteur ou du destinataire de la commande.
        </p>
        <p>
          L'Acheteur ou, le cas échéant, le destinataire de la commande, doit :
        </p>
        <p>
          Informer JFrag de sa volonté de se rétracter dans les quatorze (14)
          jours suivant la réception de la commande, par une déclaration claire
          ou en utilisant le formulaire de rétractation modèle figurant en
          annexe des présentes Conditions Générales de Vente. Cette information
          doit être envoyée par courrier à l'adresse de JFrag ou par email à
          l’adresse de JFrag. Dans le cas où l'acheteur notifie à JFrag sa
          volonté de se rétracter avant d’avoir reçu son colis, il est informé
          qu’il n’est pas techniquement possible pour JFrag de bloquer
          l’expédition des produits une fois la commande validée. La commande
          sera alors expédiée à l’Acheteur, qui s’engage, conformément à
          l’article L.221-23 du Code de la Consommation, à retourner les
          produits concernés dans un délai maximum de quatorze (14) jours à
          compter de cette notification faite à JFrag.
        </p>
      </section>
    </div>
  );
};
