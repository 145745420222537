import React from "react";
import styles from "./orderSuccess.module.css";
import { useNavigate } from "react-router-dom";
export const OrderSuccess = () => {
  const navigate = useNavigate();
  return (
    <main className={styles.order_success_container}>
      <section>
        <h1>Votre commande est passé avec succés.</h1>
        <button onClick={() => navigate("/account")}>Voir mes commandes</button>
      </section>
    </main>
  );
};
