import React from "react";
import styles from "./editableProfile.module.css";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import * as v from "valibot";
import { Input } from "../common/input/Input";
import { useUpdateUserMutation } from "../../features/api/user/userApi";
import { Loader } from "../common/loader/Loader";
import { ErrorMessage } from "../common/errorMessage/ErrorMessage";
import { useDispatch } from "react-redux";
import { updateUser } from "../../features/api/user/userSlice";
import { TikIcon } from "../common/tikIcon/TikIcon";
const schema = v.object({
  firstName: v.pipe(
    v.string("Prénom est obligatoire"),
    v.minLength(3, "Doit comporter au moins 3 caractères")
  ),
  lastName: v.pipe(
    v.string("Nom est obligatoire"),
    v.minLength(3, "Doit comporter au moins 3 caractères")
  ),
  phone: v.pipe(
    v.string("Numéro de portable est obligatoire"),
    v.length(10, "Doit comporter 10 caractères"),
    v.check((value) => /^\d+$/.test(value), "Chiffres uniquement")
  ),

  address: v.object({
    doorNo: v.pipe(
      v.string("Numéro est obligatoire"),
      v.minLength(1, "Doit comporter au moins 1 caractère"),
      v.check((value) => /^\d+$/.test(value), "Chiffres uniquement")
    ),
    extra: v.pipe(v.string("Compliment est facultatif")),
    road: v.pipe(
      v.string("Rue est obligatoire"),
      v.minLength(3, "Doit comporter au moins 3 caractères")
    ),
    postCode: v.pipe(
      v.string("Code postal est obligatoire"),
      v.minLength(5, "Doit comporter au moins 5 caractères"),
      v.check((value) => /^\d+$/.test(value), "Chiffres uniquement")
    ),
    district: v.pipe(
      v.string("Ville est obligatoire"),
      v.minLength(3, "Doit comporter au moins 3 caractères")
    ),
    country: v.pipe(
      v.string("Pays est obligatoire"),
      v.minLength(3, "Doit comporter au moins 3 caractère")
    ),
  }),
  deliveryAddress: v.object({
    doorNo: v.pipe(
      v.string("Numéro est obligatoire"),
      v.minLength(1, "Doit comporter au moins 1 caractère"),
      v.check((value) => /^\d+$/.test(value), "Chiffres uniquement")
    ),
    extra: v.pipe(v.string("Compliment est facultatif")),
    road: v.pipe(
      v.string("Rue est obligatoire"),
      v.minLength(3, "Doit comporter au moins 3 caractères")
    ),
    postCode: v.pipe(
      v.string("Code postal est obligatoire"),
      v.minLength(5, "Doit comporter au moins 5 chiffre"),
      v.check((value) => /^\d+$/.test(value), "Chiffres uniquement")
    ),
    district: v.pipe(
      v.string("Ville est obligatoire"),
      v.minLength(3, "Doit comporter au moins 3 caractère")
    ),
  }),
});

export const EditableProfile = ({ info, handleEdit }) => {
  const dispatch = useDispatch();
  const [updateUserInfo, { isLoading, isError, isSuccess }] =
    useUpdateUserMutation();

  const onSubmit = async (userInfo) => {
    try {
      await updateUserInfo(userInfo).unwrap();
      dispatch(updateUser(userInfo));
      setTimeout(() => {
        handleEdit();
      }, 1000);
    } catch (err) {}
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      firstName: info.firstName || "",
      lastName: info.lastName || "",
      phone: info.phone || "",
      address: {
        doorNo: info.address?.doorNo || "",
        extra: info.address?.extra || "",
        road: info.address?.road || "",
        postCode: info.address?.postCode || "",
        district: info.address?.district || "",
        country: info.address?.country || "",
      },
      deliveryAddress: {
        doorNo: info.deliveryAddress?.doorNo || "",
        extra: info.deliveryAddress?.extra || "",
        road: info.deliveryAddress?.road || "",
        postCode: info.deliveryAddress?.postCode || "",
        district: info.deliveryAddress?.district || "",
      },
    },
    resolver: valibotResolver(schema),
  });

  let content = null;
  if (isLoading) {
    content = <Loader />;
  } else if (isError) {
    content = <ErrorMessage />;
  } else {
    content = (
      <div className={styles.edit_profile_container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className={styles.edit_profile_info}>
            <Input
              label="Prénom"
              name="firstName"
              register={register}
              error={errors.firstName}
            />
            <Input
              label="Nom"
              name="lastName"
              register={register}
              error={errors.lastName}
            />
            <Input
              label="Phone"
              name="phone"
              register={register}
              error={errors.phone}
            />
          </section>
          <section className={styles.edit_address}>
            <section className={styles.edit_billing_address}>
              <h2>Adresse de facturation</h2>
              <Input
                label="No"
                name="address.doorNo"
                register={register}
                error={errors.address?.doorNo}
              />
              <Input
                label="Complément"
                name="address.extra"
                register={register}
                error={errors.address?.extra}
              />

              <Input
                label="Rue"
                name="address.road"
                register={register}
                error={errors.address?.road}
              />
              <Input
                label="Code postal"
                name="address.postCode"
                register={register}
                error={errors.address?.postCode}
              />
              <Input
                label="Ville"
                name="address.district"
                register={register}
                error={errors.address?.district}
              />
              <Input
                label="Pays"
                name="address.country"
                register={register}
                error={errors.address?.country}
              />
            </section>
            <section className={styles.edit_delivery_address}>
              <h2>Adresse de livraison</h2>
              <Input
                label="No"
                name="deliveryAddress.doorNo"
                register={register}
                error={errors.deliveryAddress?.doorNo}
              />
              <Input
                label="Complément"
                name="deliveryAddress.extra"
                register={register}
                error={errors.deliveryAddress?.extra}
              />

              <Input
                label="Rue"
                name="deliveryAddress.road"
                register={register}
                error={errors.deliveryAddress?.road}
              />
              <Input
                label="Code postal"
                name="deliveryAddress.postCode"
                register={register}
                error={errors.deliveryAddress?.postCode}
              />
              <Input
                label="Ville"
                name="deliveryAddress.district"
                register={register}
                error={errors.deliveryAddress?.district}
              />
                <section className={styles.country_default_section}>
                <p>
                  <span>Pays</span>
                  <span>:</span>
                </p>
                <p>France</p>
                </section>
              <p className={styles.country_warning}>***la livraison se fait uniquement en france.</p>
            </section>
          </section>
          <button
            className={styles.edit_profile_submit}
            type="submit"
            disabled={isSubmitting}>
            {isSubmitting ? "En cours" : "Metrre à jour"}{" "}
            <span>{isSuccess && <TikIcon />}</span>
          </button>
        </form>
      </div>
    );
  }

  return content;
};
