import React from 'react'
import { Payment } from '../../components/checkout/Payment'
import styles from './payOrder.module.css'

export const PayOrder = () => {
  return (
    <main className={styles.pay_order_main}>
      <Payment />
    </main>
  )
}
