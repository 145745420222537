import React from 'react'
import styles from './rating.module.css'
import starFilled from '../../assests/star_fill.png'
import starEmpty from '../../assests/star_empty.png'
export const Rating = ({ rate }) => {
  return (
    <div className={styles.rating}>
      <img src={starFilled} alt='étoile rempli' />
      <img src={starFilled} alt='étoile rempli' />
      <img src={starFilled} alt='étoile rempli' />
      <img src={starFilled} alt='étoile rempli' />
      <img src={starEmpty} alt='étoile vide' />
    </div>
  )
}
