import React from "react";
import { Filter } from "../../components/products/filter/Filter";
import { ProductCart } from "../../components/products/productCart/ProductCart";
import { PageHeader } from "../../components/common/pageHeader/PageHeader";

export const AllProduct = () => {
  return (
    <main>
      <PageHeader title={"Tous nous produits"} />
      <Filter />
      <ProductCart />
    </main>
  );
};
