import React from "react";
import bg from "../../assests/agarwood_yellow_shade.png";
import { PageHeader } from "../../components/common/pageHeader/PageHeader";
import styles from "./about.module.css";
export const About = () => {
  return (
    <>
      <PageHeader title={"Propos"} />
      <main className={styles.about_main}>
        <section className={styles.introduction}>
          <div className={styles.initial}>
            <p className={styles.letter}>JF</p>
            <p className={styles.who_are_we}>
              <span></span>
              <span></span>
              <span></span>
              qui sommes nous
              <span></span>
              <span></span>
              <span></span>
            </p>
          </div>
          <p className={styles.description}>
            Chez JFrag, nous sommes passionnés par l'offre de parfums luxueux
            qui transmettent l'essence de l'élégance et du raffinement. Basés en
            France, nous nous engageons à fournir à nos clients des parfums de
            haute qualité et de longue durée qui enrichissent votre expérience
            quotidienne. Nos parfums sont fabriqués pour laisser une impression
            durable - un parfum que vous ne regretterez jamais d'avoir porté.
            Tous nos produits sont fabriqués aux Émirats arabes unis à partir
            des meilleures matières premières, triées sur le volet pour leur
            qualité exceptionnelle. La durabilité est au cœur de tout ce que
            nous faisons, garantissant que chaque parfum n'est pas seulement
            luxueux, mais aussi respectueux de l'environnement. Alors que JFrag
            est fièrement basé en France, nous collaborons avec{" "}
            <a
              href="https://www.balhowaimil.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link_balhowaimil}
            >
              Balhowaimil
            </a>
            , un partenaire de confiance qui est un leader de l'industrie du
            parfum au Moyen-Orient depuis des années. Ce partenariat nous permet
            de combiner l'expertise du Moyen-Orient avec la finesse française,
            offrant ainsi une expérience exclusive à nos clients en France.
            Explorez notre collection et découvrez votre parfum signature - un
            parfum qui dure, qui inspire et qui est fabriqué avec passion et
            soin.
          </p>
        </section>
        <section className={styles.about_bg}>
          <p>"Aim to give you new freshness everyday"</p>
          <img src={bg} alt="Agar about page background" />
        </section>
        <section className={styles.vision}>
          <p>
            Notre engagement envers l'excellence va au-delà de la simple
            création de parfums ; nous concevons des expériences olfactives
            uniques. En alliant innovation et tradition, nous offrons des
            senteurs qui incarnent la modernité tout en respectant le patrimoine
            de la parfumerie. Avec une attention minutieuse aux détails, chaque
            parfum est conçu pour séduire, captiver et envelopper son porteur
            dans une aura d'élégance. Chez nous, le parfum est bien plus qu'un
            accessoire - c'est une signature, un mode d'expression intemporel.
          </p>
        </section>
      </main>
    </>
  );
};
