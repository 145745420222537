import { createSlice } from "@reduxjs/toolkit";
import { checkPriceAndStock } from "../products/thunk/checkPriceAndStock";
import getDiscountPrice from "../../../utils/calculateDiscountPrice";
const initialState = {
  productList: [],
  itemCount: 0,
  total: 0,
  isLowStock: false,
};

const countItemAndTotal = (state) => {
  let qty = 0;
  let total = 0;
  state.productList.forEach((product) => {
    qty += product.qty;
    if (product.reduction > 0) {
      total += getDiscountPrice(product.price, product.reduction) * product.qty;
    } else {
      total += product.price * product.qty;
    }
  });
  state.total = total.toFixed(2);
  state.itemCount = qty;
};
const updateStockStatus = (state) => {
  state.isLowStock = !state.productList.every(
    (product) => product.qty <= product.inStock
  );
};

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    setProductList: (state, action) => {
      const newProducts = action.payload;
      newProducts.forEach((newProduct) => {
        const existingProduct = state.productList.find(
          (product) => product.id === newProduct.id
        );
        if (existingProduct) {
          existingProduct.qty += newProduct.qty;
        } else {
          state.productList.push(newProduct);
        }
      });
      countItemAndTotal(state);
    },
    addProductToBasket: (state, action) => {
      const newProduct = action.payload;
      const existingProduct = state.productList.find(
        (product) => product.id === newProduct.id
      );
      if (existingProduct) {
        existingProduct.qty += newProduct.qty;
      } else {
        state.productList.push(newProduct);
      }
      countItemAndTotal(state);
    },
    increaseProductQuantity: (state, action) => {
      const productId = action.payload;
      const existingProduct = state.productList.find(
        (product) => product.id === productId
      );

      if (existingProduct) {
        existingProduct.qty += 1;
      }
      countItemAndTotal(state);
    },
    decreaseProductQuantity: (state, action) => {
      const productId = action.payload;
      const existingProduct = state.productList.find(
        (product) => product.id === productId
      );

      if (existingProduct) {
        if (existingProduct.qty > 1) {
          existingProduct.qty -= 1;
        } else {
          state.productList = state.productList.filter(
            (product) => product.id !== productId
          );
        }
      }
      countItemAndTotal(state);
      updateStockStatus(state);
    },
    removeProductFromBasket: (state, action) => {
      const productId = action.payload;
      state.productList = state.productList.filter(
        (product) => product.id !== productId
      );
      countItemAndTotal(state);
      updateStockStatus(state);
    },
    clearBasket: (state, action) => {
      state.productList = [];
      state.itemCount = 0;
      state.total = 0;
      state.isLowStock = false;
      localStorage.setItem("jfragBasket", JSON.stringify([]));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkPriceAndStock.fulfilled, (state, action) => {
      // Update the state with new prices, discoun and stock information from the API
      const updatedInfoList = action.payload;
      updatedInfoList.forEach((updatedProduct) => {
        const existingProduct = state.productList.find(
          (product) => product.id === updatedProduct.id
        );
        if (existingProduct) {
          existingProduct.price = updatedProduct.price;
          existingProduct.inStock = updatedProduct.quantity;
          existingProduct.reduction = updatedProduct.reduction;
          if (
            updatedProduct.quantity === 0 ||
            existingProduct.qty > updatedProduct.quantity
          )
            state.isLowStock = true;
        }
      });
      countItemAndTotal(state);
    });
  },
});

export const {
  clearBasket,
  setProductList,
  addProductToBasket,
  increaseProductQuantity,
  decreaseProductQuantity,
  removeProductFromBasket,
} = basketSlice.actions;
export default basketSlice.reducer;
