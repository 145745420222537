import React from 'react'
import { Navbar } from '../navbar/Navbar'

import styles from './header.module.css'

export const Header = () => {
  return (
    <header className={styles.mainHeader}>
      <Navbar />
    </header>
  )
}
