import React from 'react'
import { SingleProduct } from '../../components/singleProduct/SingleProduct'
import { SuggestedProduct } from '../../components/singleProduct/suggestedProduct/SuggestedProduct'
import { PageHeader } from '../../components/common/pageHeader/PageHeader'

export const DetailProduct = () => {
  return (
    <>
      <PageHeader title={'Description'} />
      <SingleProduct />
      <SuggestedProduct />
    </>
  )
}
