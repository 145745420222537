import React from "react";
import styles from "./promoproduct.module.css";
import getDiscountPrice from "../../../../utils/calculateDiscountPrice";
import { Link } from "react-router-dom";

export const PromoProduct = ({ product }) => {
  const {
    id,
    name,
    shortDescription: description,
    category,
    size,
    price,
    reduction,
    cartImage: photo,
  } = product;
  return (
    <Link to={`/product/${id}`}>
      <figure className={styles.promo_item}>
        <section className={styles.decription}>
          <h4>{name}</h4>
          <section>
            <p>{description}</p>
            <p>
              <span>{category}</span>
              <b> | </b>
              <span>{size}ML</span>
            </p>
          </section>
        </section>
        <img src={`data:image/jpeg;base64,${photo}`} alt="Perfum" />
        <figcaption>
          <span>{price} €</span>
          <span> - {reduction}% </span>
          <span>{getDiscountPrice(price, reduction)} €</span>
        </figcaption>
      </figure>
    </Link>
  );
};
