import React, { useState, useRef, useEffect } from "react";
import styles from "./customDropdown.module.css"; // Import the CSS module for styling

export const CustomDropdown = ({
  options,
  placeholder,
  handleFilterByName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      setFilteredOptions(options); // Reset filter when opening dropdown
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Handle filtering input change
  const handleFilterChange = (e) => {
    const query = e.target.value;
    handleFilterByName(query);
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(query.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (selectedOption?.value) {
      handleFilterByName(selectedOption.value);
    } else {
      handleFilterByName("");
    }
  }, [selectedOption]);
  return (
    <div className={styles.custom_dropdown} ref={dropdownRef}>
      <div className={styles.dropdown_header} onClick={toggleDropdown}>
        {selectedOption ? selectedOption.label : placeholder}
        <span className={styles.arrow}>{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <div className={styles.dropdown_content}>
          <input
            type="text"
            placeholder="Options..."
            className={styles.dropdown_filter}
            onChange={handleFilterChange}
          />
          <div className={styles.dropdown_list}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className={`${styles.dropdown_item} ${
                    selectedOption && selectedOption.value === option.value
                      ? styles.dropdown_item_selected
                      : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className={styles.dropdown_item + " " + styles.no_results}>
                Aucun résultat trouvé
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
