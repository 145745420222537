import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { motion } from "framer-motion";
import styles from "./navbar.module.css";
import loginIcon from "../../../assests/login.svg";
import logoutIcon from "../../../assests/logout.svg";
import profile from "../../../assests/profile.png";
import basket from "../../../assests/basket.png";
import burgerMenu from "../../../assests/burger.png";
import menuClose from "../../../assests/menu_close.png";
import { clearUser } from "../../../features/api/user/userSlice";
import { useAuth } from "../../../customHooks/useAuth";

const svgLogoVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(215, 215, 0,0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(215, 215, 0,1)",
  },
};

export const Navbar = () => {
  const [activeIdx, setActiveIdx] = useState(null);
  const [show, setShow] = useState(false);
  const [animate, setAnimate] = useState(false);
  const toggleMenu = () => {
    setShow(!show);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { itemCount } = useSelector((state) => state.basket);
  const isAuthenticated = useAuth();
  const handleLogout = () => {
    dispatch(clearUser());
  };
  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    setAnimate(true);
    const timeoutId = setTimeout(() => {
      setAnimate(false);
    }, 1000); // Match this duration with your CSS transition duration
    return () => clearTimeout(timeoutId);
  }, [itemCount]);
  return (
    <div className={styles.nav_container}>
      <nav className={styles.nav}>
        <NavLink to="/" onClick={() => setActiveIdx(1)}>
          <motion.div className={styles.logo}>
            <motion.svg
              xmlns="httpl://www.w3.org/2000/svg"
              viewBox="0 0 1200 700"
            >
              <motion.path
                d="M288.81,89.5c1.05,2.36,1,4.87,1,7.37q0,89.48,0,179A86.73,86.73,0,0,1,286,301.4c-4.93,16-17.22,25.2-30.85,33-14.93,8.5-31.68,11.82-48.12,15.86-5,1.23-10.18,1.6-15.31,2.17-22,2.42-44.1,3.23-66.23,1.29a156.41,156.41,0,0,0-16.43-.84c-12.71.24-25.2-2.21-37.85-2.28-3.14,0-4.24-1.4-4.65-4.47-1.18-9,.91-17.94,0-26.91-.34-3.2,2.32-2.88,4.17-2.69,6.78.66,13.59,1.38,20.26,2.49a357.85,357.85,0,0,0,68.63,4.34A141.51,141.51,0,0,0,195,317.59c17.68-5.09,26.3-17.78,28.79-35.2,2.15-15.07.95-30.27,1-45.41.21-38-.08-76,.07-114,0-5.12-1.66-6.53-6.62-6.5-28.33.2-56.65.1-85,.09-6.71,0-7.42-.47-8-7.2a67.78,67.78,0,0,1,.61-19.9Z"
                variants={svgLogoVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  default: {
                    duration: 2,
                    ease: "easeInOut",
                    delay: 0,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                  fill: {
                    duration: 2,
                    ease: "easeIn",
                    delay: 1,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                }}
              />
              <motion.path
                d="M475.81,89.5c1.23,2.49,1.06,5.19,1.07,7.85q0,16.74,0,33.49c0,6.71-.94,7.71-7.88,7.75-16.16.07-32.33,0-48.49,0-7,0-14,.1-21,0-3.92-.08-5.72,1.26-5.79,5.47-.19,12,.8,24-.72,35.94-.57,4.5,2.54,5.59,6.39,5.57,11.33-.05,22.66,0,34,0,12.5,0,25,.12,37.5,0,4.24-.06,6.06,1.5,6,5.81-.11,12.5-.14,25,0,37.49.06,4.7-2.8,5.63-6.4,5.73-5.83.15-11.66.08-17.5.09l-52,0c-6.94,0-7.25.3-7.25,7.39q0,50.5,0,101c0,10.37-.06,10.41-10.18,10.4-11.33,0-22.66,0-34,0-6.37,0-7.77-1.33-7.77-7.56q0-123.74,0-247.46a84,84,0,0,1,.93-8.92Z"
                variants={svgLogoVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  default: {
                    duration: 2,
                    ease: "easeInOut",
                    delay: 0,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                  fill: {
                    duration: 2,
                    ease: "easeIn",
                    delay: 1,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                }}
              />
              <motion.path
                d="M990.81,402.5c-5.49,7.47-10.14,15.53-17.18,21.86-7.92,7.13-17.09,11.95-26.89,15.71-9.16,3.51-18.56,6.27-28,9.07-8.17,2.42-16.35,1.75-24.64,1.91-9.08.18-18.22-1.07-27.28.6-3.15.58-5.73.82-7.09-3.69-1-3.28-5.5-3.9-8.89-4.48-7.13-1.21-13.76-3.91-20.23-6.86-9.72-4.43-15.44-12.23-17.59-22.75-1.3-6.36,1.78-11.42,5.81-15.1,8-7.28,17.55-12.4,27.54-16.59,3.39-1.42,5.43-3.72,5.42-7.56,0-3.49.08-7,0-10.49-.14-6,2.12-10.48,7.57-13.51,6.23-3.46,12.06-7.64,18.68-10.39,1-.41,1.85-.92,1.92-2.05.08-1.32-1.07-1.54-2-1.85-4.66-1.61-9.26-3.45-14-4.73-15.84-4.24-29.86-11.26-39.14-25.45-11.81-18.06-14.92-37.63-10-58.64,5.22-22.13,18.61-37.75,38.67-47.37,22.07-10.59,45-13.3,68.64-4.61a180.2,180.2,0,0,1,32.53,16.15c6.07,3.75,7.83,2.67,8-4.45.21-8.65.61-17.29.65-25.94,0-3.21,1.39-3.45,4-3.25,7.79.61,15.59,1,23.39,1.46v9c-6.86,8.14-12.71,17.06-19.7,25.12-3.86,4.44-8.09,9.14-5.91,16,2.22,7,2.76,14.38,4.33,21.53,6,27.51-8,54.89-30.52,69.51-17.3,11.22-36.82,17.54-55.39,26-6.92,3.15-14.46,5-20.64,9.76-.9.69-2.3,1.15-2,2.57s1.76,1.37,2.89,1.36c10.65,0,21.4.76,31.94-.36,21-2.25,41.84-1,62.61,1.85,6.44.87,12.72,3,18,7.7,6.11,5.45,9.92,12.4,14.37,19Zm-150-153.2a82.93,82.93,0,0,0,14.81,47.53c8.45,12.43,18.84,22.92,33.31,28.2,8.59,3.14,17.64,4.25,26.3.37,19.27-8.63,27.52-24.69,28.47-44.88.9-19-4.61-36.25-15.27-52.06a73.26,73.26,0,0,0-25.22-23.55c-16.59-9.28-40.35-7.73-52.19,8.82C843.56,224.19,840.74,235.87,840.84,249.3Zm40.52,127.33c-7.11-1.1-15.13.15-21.77,7.66-4,4.52-9.52,7.69-14.37,11.44-3,2.34-4.26,5-2,8.6,1.79,2.79,3.38,5.74,5.37,8.39,5.26,7,10.42,14.11,18.35,18.66a55.44,55.44,0,0,0,29.19,7.07c13.45-.25,26.8-2.78,40.32-2a6.32,6.32,0,0,0,4.17-1.4c8.07-5.88,15.19-12.89,22.76-19.36,2.54-2.16,2.52-4.37.62-6.94-4-5.34-7.76-10.8-11.77-16.1A25.44,25.44,0,0,0,938.1,383C920.14,378.45,901.86,376.47,881.36,376.63Z"
                variants={svgLogoVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  default: {
                    duration: 2,
                    ease: "easeInOut",
                    delay: 0,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                  fill: {
                    duration: 2,
                    ease: "easeIn",
                    delay: 1,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                }}
              />
              <motion.path
                d="M664.21,205.91c3.14-3.46,6.68-4.25,9.78-5.39,9-3.33,18.31-5.92,27.45-8.89,13.31-4.32,32.64,3.8,40.63,17.89,10.4,18.38,17.28,37.91,22.69,58.19,4.57,17.16,9.76,34.18,17.12,50.41,5.13,11.31,13.61,19.94,22.69,28.14,2.54,2.28,7.85,4,6.21,7.9-1.35,3.22-6.36,1.18-9.71,1.23a110.43,110.43,0,0,1-31-4.41c-4.33-1.2-6.71-5-9-8.28-7.94-11.6-13.18-24.53-17-38-1.11-3.87-2.75-7.49-4-11.25-.32-.92-.63-2-1.9-1.94-1,.09-1.06,1.14-1.35,1.89-4,10.1-9.61,19.31-14.72,28.83-12.09,22.53-36.8,36.31-62.23,32.95-13.45-1.78-25-8.77-30.21-21.7-5.87-14.62-4-29.31,6.68-41.63,10.42-12,24.26-19.24,38.54-25.77,15.59-7.12,32-12.17,47.75-18.69,5.54-2.28,5.78-3.17,2.57-8-7.18-10.78-14.28-21.63-22.32-31.82A7.42,7.42,0,0,1,701,204.7c-.59-3.22-2.88-4.13-5.49-3.86-4.44.44-9,.37-13.26,2.07C676.78,205.08,671,205.26,664.21,205.91Zm68.46,60.24c0-3.5.08-7,0-10.49-.12-4-2.78-5.14-5.81-3.68-9.75,4.71-20.28,7.31-30.2,11.56-11.72,5-23.39,10-32.92,18.89-8.44,7.91-13.79,17-12.78,28.95,1.48,17.45,14.94,27,29.11,25.56,15-1.49,28.07-9.18,37.65-21.25C729.15,301.25,733.14,284.31,732.67,266.15Z"
                variants={svgLogoVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  default: {
                    duration: 2,
                    ease: "easeInOut",
                    delay: 0,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                  fill: {
                    duration: 2,
                    ease: "easeIn",
                    delay: 1,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                }}
              />
              <motion.path
                d="M548.2,220.7c8.84-8.44,16.95-16.95,26.62-23.86,7.09-5.06,14-5.32,21.66-2.51a147.09,147.09,0,0,1,20.8,9.56,18.49,18.49,0,0,0,13.42,1.93c3.41-.71,6.87-1.24,10.31-1.83,1.15-.2,2.43-.52,3.1.81.56,1.11-.38,1.81-1.06,2.53-7.49,7.93-14.64,16.2-24.68,21.21-5.14,2.57-10,2.88-15.21.25a187.74,187.74,0,0,1-26.39-16c-3-2.21-5.22-2.21-7.37,1.2s-5.75,5.53-8.87,8c-8.33,6.53-12.63,14.4-13,25.47-.55,17.14-.65,34.27-.72,51.41-.05,12.06,1.91,23.84,4.14,35.62.63,3.29,3.16,5.13,5.59,6.73,4,2.64,8.36,4.79,12.53,7.2,1.78,1,4.78,1.51,4,4.22s-3.64,2-5.64,1.77c-15.77-2-31.6-.69-47.39-.83-5.47-.06-10.94.69-16.41.94-1.58.08-3.66.49-4.38-1.49s1.24-2.84,2.48-3.86c3.44-2.87,7-5.59,10.37-8.56,6-5.29,6.19-12.75,6.44-19.8.63-18.12,2-36.24,1.09-54.39-.84-17.29-1.19-34.59-.93-51.91.06-4-1.58-6.71-5.79-7.46-4.8-.86-8.57-4.41-13.42-5.23-.94-.16-2.08-.69-2.08-1.85s1.2-1.41,2.17-1.58q21.89-3.79,43.76-7.56c3.3-.57,3.46,1.73,3.47,4,0,6.82-.06,13.65,0,20.48C546.92,217.05,546.2,219,548.2,220.7Z"
                variants={svgLogoVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  default: {
                    duration: 2,
                    ease: "easeInOut",
                    delay: 0,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                  fill: {
                    duration: 2,
                    ease: "easeIn",
                    delay: 1,
                    repeatType: "reverse",
                    repeatDelay: 1,
                  },
                }}
              />
            </motion.svg>
          </motion.div>
        </NavLink>
        <ul
          className={`${styles.main_menu} ${show ? styles.show : null}`}
          onClick={toggleMenu}
        >
          <li
            className={activeIdx === 1 ? styles.active : ""}
            onClick={() => setActiveIdx(1)}
          >
            <NavLink to="/">Acceuil</NavLink>
          </li>
          <li
            className={activeIdx === 2 ? styles.active : ""}
            onClick={() => setActiveIdx(2)}
          >
            <NavLink to="/all-product">Produit</NavLink>
          </li>
          <li
            className={activeIdx === 3 ? styles.active : ""}
            onClick={() => setActiveIdx(3)}
          >
            <NavLink to="/gents">Homme</NavLink>
          </li>
          <li
            className={activeIdx === 4 ? styles.active : ""}
            onClick={() => setActiveIdx(4)}
          >
            <NavLink to="/ladies">Femme</NavLink>
          </li>
          <li
            className={activeIdx === 5 ? styles.active : ""}
            onClick={() => setActiveIdx(5)}
          >
            <NavLink to="/about">A Propos</NavLink>
          </li>
        </ul>
        <section className={styles.util_menu}>
          {isAuthenticated ? (
            <img
              src={logoutIcon}
              alt="logout"
              onClick={() => {
                handleLogout();
                setActiveIdx(null);
              }}
            />
          ) : (
            <img
              src={loginIcon}
              alt="login"
              onClick={() => {
                handleLogin();
                setActiveIdx(null);
              }}
            />
          )}
          {isAuthenticated && (
            <NavLink to={"/account"} onClick={() => setActiveIdx(null)}>
              <img src={profile} alt="account" />
            </NavLink>
          )}
          <NavLink
            to="/basket"
            className={styles.basket_icon}
            onClick={() => setActiveIdx(null)}
          >
            <img src={basket} alt="basket" />
            <span className={styles.qty_change}>{!animate && itemCount}</span>
            <span
              className={animate && itemCount ? styles.qty_change_animate : ""}
            >
              {itemCount}
            </span>
          </NavLink>
          {!show && (
            <img
              className={styles.menu_mobile}
              src={burgerMenu}
              alt="menu mobile"
              onClick={toggleMenu}
            />
          )}
          {show && (
            <img
              className={styles.menu_mobile}
              src={menuClose}
              alt="menu close"
              onClick={toggleMenu}
            />
          )}
        </section>
      </nav>
    </div>
  );
};
