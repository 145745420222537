import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  size: '',
  title: '',
  maxPrice: 500,
}

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSize: (sate, action) => {
      sate.size = action.payload
    },
    setPrice: (sate, action) => {
      sate.maxPrice = action.payload
    },
    setTitle: (sate, action) => {
      sate.title = action.payload
    },
  },
})

export const { setSize, setPrice, setTitle } = filterSlice.actions
export default filterSlice.reducer
