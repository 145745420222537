import React from 'react'
import styles from './tikIcon.module.css'
export const TikIcon = () => {
  return (
    <i className={styles.tik_icon}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 130.2 130.2'
      >
        <polyline
          className={styles.path + ' ' + styles.check}
          fill='none'
          stroke='#d5b817'
          strokeWidth='10'
          strokeLinecap='round'
          strokeMiterlimit='10'
          points='100.2,40.2 51.5,88.8 29.8,67.5 '
        />
      </svg>
    </i>
  )
}
