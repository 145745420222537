import React from "react";
import styles from "./newproduct.module.css";
import { Link } from "react-router-dom";

export const NewProduct = ({ product }) => {
  const {
    id,
    name,
    shortDescription: description,
    category,
    size,
    price,
    cartImage: photo,
  } = product;
  return (
    <Link to={`/product/${id}`} className={styles.new_item}>
      <figure>
        <section>
          <h4>{name}</h4>
          <p>{description}</p>
          <p>
            <span>{category}</span>
            <span>{size}ML</span>
          </p>
          <figcaption>{price}€</figcaption>
        </section>
        <img src={`data:image/jpeg;base64,${photo}`} alt="Perfum" />
      </figure>
    </Link>
  );
};
