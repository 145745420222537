import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOrderByIdAndUserQuery } from '../../features/api/order/orderApi'
import { ErrorMessage } from '../common/errorMessage/ErrorMessage'
import { Loader } from '../common/loader/Loader'
import styles from './purchaseDetails.module.css'
import dateFormatter from '../../utils/dateFormatter'
import arrowIcon from '../../assests/icons/up-arrow-svgrepo-com.svg'
export const PurchaseDetails = () => {
  const navigate = useNavigate()
  const { orderId } = useParams()
  const {
    data: order,
    isLoading,
    isError,
  } = useGetOrderByIdAndUserQuery(orderId)

  let content = null
  if (isLoading) content = <Loader />
  if (isError) content = <ErrorMessage />
  if (!isLoading && !isError && order) {
    content = (
      <div className={styles.purchase_details_container}>
        <button
          className={styles.btn_see_orders}
          onClick={() => navigate('/account')}
        >
          <img src={arrowIcon} alt='Revenir à la page précedente' />
          Voir toutes les commandes
        </button>
        <section className={styles.order_info}>
          <p>Commande : {order.orderRef}</p>
          <p>Date : {dateFormatter(order.createdAt)}</p>
          <p>Email : {order.email}</p>
        </section>
        <div className={styles.purchase_address}>
          <section className={styles.purchase_billing_address}>
            <h4>Facturation</h4>
            <p>
              {' '}
              {order.firstName} {order.lastName}{' '}
            </p>
            <p>Adresse : {order.address}</p>
          </section>
          <section className={styles.purchase_delivery_address}>
            <h4>Livraison</h4>
            <p>
              {' '}
              {order.firstName} {order.lastName}{' '}
            </p>
            <p>Adresse : {order.deliveryAddress}</p>
          </section>
        </div>
        <section className={styles.purchase_items}>
          <h4>Item(s)</h4>
          {order.orderDetailsResponseDtos.map((item) => (
            <div className={styles.purchase_item} key={item.id}>
              <img
                src={`data:image/jpeg;base64,${item.cartImage}`}
                alt='Perfum'
              />
              <p>
                {item.name} x {item.quantity}
              </p>
              <p>{item.size}ml</p>
              <p>{item.price}€/u</p>
            </div>
          ))}
        </section>
        <p className={styles.purchase_total}>Total : {order.total}€</p>
      </div>
    )
  }

  return content
}
