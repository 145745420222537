import React from 'react'
import styles from './itemToPay.module.css'
import getDiscountPrice from '../../utils/calculateDiscountPrice'
export const ItemToPay = ({ item }) => {
  return (
    <div className={styles.item_to_pay}>
      <section className={styles.item_to_pay_description}>
        <p className={styles.item_to_pay_size}>{item.qty}</p>
        <img src={`data:image/jpeg;base64,${item.image}`} alt='Perfume' />
        <h4>{item.name}</h4>
      </section>
      <p className={styles.item_to_pay_size}>{item.size} ML</p>
      <section className={styles.item_to_pay_price}>
        {!item.reduction && <p>{item.price * item.qty}€</p>}
        {item.reduction > 0 && (
          <p>{getDiscountPrice(item.price, item.reduction) * item.qty}€</p>
        )}
      </section>
    </div>
  )
}
