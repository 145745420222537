import React, { useEffect, useState } from "react";
import styles from "./addressPayment.module.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../customHooks/useAuth";

export const AddressPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [animating, setAnimating] = useState(false);
  const isAuthenticated = useAuth();
  const { total, itemCount, isLowStock } = useSelector((state) => state.basket);
  const { info } = useSelector((state) => state.user);
  useEffect(() => {
    // Trigger the animation whenever total changes
    setAnimating(true);
    const timer = setTimeout(() => setAnimating(false), 850); // Animation duration should match the CSS animation duration

    return () => clearTimeout(timer);
  }, [total]);

  const pay = () => {
    if (isLowStock) {
      alert(
        "Nous n'avons pas la disponibilité suffisante pour certains articles, veuillez modifier la liste des articles.!"
      );
      return;
    }
    if (!isAuthenticated) {
      navigate("/login", { state: { from: location } });
    } else {
      navigate("/place-order");
    }
  };

  let deliveryCharge = 0;
  let finalTotal = Number(total) + Number(deliveryCharge);
  finalTotal = finalTotal.toFixed(2);
  return (
    <div className={styles.address_payment}>
      <h3>Adress | Paiment</h3>
      <div className={styles.address_payment_info}>
        <section className={styles.payment_info}>
          <h4>
            <span>
              {itemCount} Item{itemCount > 1 ? "s" : null}
            </span>
            <span className={`${animating ? styles.slideIn : ""}`}>
              {total}€
            </span>
          </h4>
          <p>
            <span>Livraison</span>
            <span>{deliveryCharge ? deliveryCharge : "offret"}</span>
          </p>
          {/* <p className={styles.delivery_chrage_condition}>
            *Livraison offret des 100€ d'achat
          </p> */}
          <h4 className={styles.payment_total}>
            <span>Total</span>
            <span>{finalTotal}€</span>
          </h4>
        </section>
        {isAuthenticated && info?.deliveryAddress ? (
          <section>
            <div className={styles.address_input_group}>
              <label htmlFor="name">
                <span>Nom Prénom</span>
                <span>:</span>
              </label>
              <input
                readOnly
                required
                name="name"
                type="text"
                placeholder="Ex: Jhon Doe"
                minLength={3}
                maxLength={50}
                value={info ? info?.lastName + " " + info?.firstName : ""}
              />
            </div>
            <div className={styles.address_input_group}>
              <label htmlFor="doorNo">
                <span>No</span>
                <span>:</span>
              </label>
              <input
                readOnly
                required
                name="doorNo"
                type="text"
                placeholder="Ex: 10"
                value={
                  info
                    ? info?.deliveryAddress?.doorNo
                      ? info?.deliveryAddress?.doorNo
                      : ""
                    : ""
                }
              />
            </div>
            <div className={styles.address_input_group}>
              <label htmlFor="road">
                <span>Rue</span>
                <span>:</span>
              </label>
              <input
                readOnly
                required
                name="road"
                type="text"
                placeholder="Ex: Rue de paris"
                value={
                  info
                    ? info?.deliveryAddress?.road
                      ? info?.deliveryAddress?.road
                      : ""
                    : ""
                }
              />
            </div>
            <div className={styles.address_input_group}>
              <label htmlFor="postCode">
                <span>Code postal</span>
                <span>:</span>
              </label>
              <input
                readOnly
                required
                name="postCode"
                type="text"
                placeholder="Ex: 75000"
                value={
                  info
                    ? info?.deliveryAddress?.postCode
                      ? info?.deliveryAddress?.postCode
                      : ""
                    : ""
                }
              />
            </div>
            <div className={styles.address_input_group}>
              <label htmlFor="district">
                <span>Ville</span>
                <span>:</span>
              </label>
              <input
                readOnly
                required
                name="district"
                type="text"
                placeholder="Ex: Paris"
                value={
                  info
                    ? info?.deliveryAddress?.district
                      ? info?.deliveryAddress?.district
                      : ""
                    : ""
                }
              />
            </div>
            <div className={styles.address_input_group}>
              <label htmlFor="phone">
                <span>Tél</span>
                <span>:</span>
              </label>
              <input
                readOnly
                required
                name="phone"
                type="text"
                placeholder="Ex: 0612345678"
                value={info ? (info?.phone ? info?.phone : "") : ""}
              />
            </div>
          </section>
        ) : (
          <p className={styles.warning}>
            {isAuthenticated
              ? "L'adresse de livraison est manquante, veuillez ajouter une adresse de livraison"
              : "Veuillez vous connecter pour effectuer l'achat"}
          </p>
        )}
      </div>
      <button
        disabled={isAuthenticated && !info?.deliveryAddress}
        onClick={pay}
      >
        Valider ma commande
      </button>
    </div>
  );
};
